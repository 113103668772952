import React from 'react';
import Footer from './Footer';
// import logo_cfit from '../assets/logo_cfit.jpg'
import cfit from '../assets/projet_cfit/1.jpg'
import cie from '../assets/logo_cie.png'
// import ListeAlumnis from './ListeAlumnis';
// import { IoReloadOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const Partenariat = () => {

    const navigate = useNavigate()

    return (
        <div>
            <section class="section-padding" style={{ paddingTop: "0px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div class="all-news-block">
                                <h3 class="news-title">
                                    <span>Nos Partenaires</span>
                                </h3>
                                <div class="all-news">
                                    <div class="row">


                                        <div class="col-lg-6 col-md-6" style={{ cursor: "pointer" }} onClick={() => navigate('/partenariat/laboratoire-technologie-et-projet-cfit-iii')}>
                                            <div class="post-block-wrapper post-float-half clearfix">
                                                <div class="post-thumbnail">
                                                    <a>
                                                        <img class="img-fluid" src={cfit} alt="post-thumbnail" />
                                                    </a>
                                                </div>
                                                <div class="post-content">
                                                    {/* <a class="post-category" >{data.categorie}</a> */}
                                                    <h2 class="post-title mt-3">
                                                        <a>LANCEMENT OFFICIEL DU PROJET CFIT</a>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <nav aria-label="pagination-wrapper" class="pagination-wrapper">
                                <ul class="pagination justify-content-center">

                                    <li class="page-item active">
                                        <a class="page-link" aria-label="Next">
                                        <IoReloadOutline style={{fontSize:"22px"}}/>
                                        </a>
                                    </li>
                                </ul>
                            </nav> */}
                        </div>

                        {/* NOS ALUMNIS */}
                        {/* <ListeAlumnis /> */}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Partenariat;
