import '../style/lireActualite.css'
import Footer from './Footer'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import cfit_1 from '../assets/projet_cfit/1.jpg'
import cfit_2 from '../assets/projet_cfit/2.jpg'
import cfit_3 from '../assets/projet_cfit/3.jpg'
import cfit_4 from '../assets/projet_cfit/4.jpg'
import cfit_5 from '../assets/projet_cfit/5.jpg'
import cfit_6 from '../assets/projet_cfit/6.jpg'

const PartenariatDetail = () => {

     return (
        <div classNameName="PartenariatDetail">

            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span ><a href="/accueil" style={{ cursor: "pointer", color:"white", fontSize: "18px" }}>Accueil</a></span> <MdKeyboardDoubleArrowRight /> <span > Partenariat</span>
            </header>

            <section className="single-block-wrapper section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div className="single-post">
                                <div className="post-header mb-5">
                                    <h3 className="post-title" style={{ fontSize: "25px", lineHeight: "33px" }}>
                                    LANCEMENT OFFICIEL DU PROJET CFIT
                                    </h3>
                                </div>
                                <div className="post-body">
                                    <div className="post-featured-image">
                                        <img src={cfit_1} className="img-fluid" alt="featured-image" /><br />
                                        <p>
                                        Le jeudi 10 Mars 2022 a eu lieu la cérémonie officielle de lancement du projet CFIT-III en Amphithéâtre A du district ( UFHB ) en présence de plusieurs autorités notamment :<br/>
- Monsieur le ministre de l'enseignement supérieur et de la recherche scientifique, Pr ADAMA DIAWARA <br/>
- l'ambassadeur de la république populaire de CHINE en CÔTE D'IVOIRE, Monsieur Wang LI<br/>
-la représentante de l'UNESCO en CÔTE D'IVOIRE, Madame Anne LEMAISTRE. <br/>
Ce projet vise à résoudre le problème de l'inadéquation formation-emploi en CÔTE D'IVOIRE. Dans ce cadre plusieurs accords et conventions ont été signés entre les institutions impliquées dans ce projet : Institut National Polytechnique ( INPHB ), Université FHB COCODY et l'UNESCO. <br/>
L'unité de formation et de recherche  SCIENCES DES STRUCTURES DE LA MATIÈRE ET TECHNOLOGIE ( UFR SSMT ) de l'université FHB de COCODY est l'une des heureuses bénéficiaires de ce projet précisément le LABORATOIRE DE TECHNOLOGIE (LAB_TECH) . Elle contribuera donc à la formation d'une main d'œuvre compétente. <br/>
L'UFR SSMT a profité de cette occasion pour presenter son nouveau site internet ou vous trouverez toutes ses informations : <a href="https://www.ufrssmt.edu.ci" target='_bank'></a>
Les étudiants des filières techniques se sont mobilisés massivement pour exprimer leur joie au lancement de ce projet. <br />
Ils se disent prêts à recevoir une formation de qualité pour participer activement à la construction de la CÔTE D'IVOIRE de demain !
                                        </p>
                                    </div>
                                    <div className="entry-content">

                                                <h2>VISITE DES COORDONATEURS DU PROJET CFIT DANS LES LOCAUX DES FILIERES TECHNIQUES</h2>
                                          
                                                <img src={cfit_5} className="img-fluid" alt="featured-image" /><br />
                                        <div className="media mb-4 single-media">
                                        Ce Mercredi 16 Novembre 2022, les filières techniques ont reçu la Coordination internationale du projet CFIT-III. <br />
Au cours de cette journée, Doyens, Professeurs, étudiants et la coordination étaient réunis dans le cadre de :<br />
👉Présenter tout ce qui avait été fait sous le compte du projet CFIT III<br />
👉Montrer l'impact du projet sur les professeurs et étudiants <br />
👉Montrer comment pouvons nous améliorer les choses afin de rendre notre apprentissage plus facile <br />
Suite à cette entrevue une visite guidée s'est faite dans les locaux des filières techniques afin de montrer à nos visiteurs nos différentes salles de TP
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* recent article */}

                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="sidebar sidebar-right">

                                <div className="widget">
                                    <h3 className="news-title">
                                        <span>En images</span>
                                    </h3>

                                    <div className="post-overlay-wrapper" style={{ cursor: "pointer" }}>
                                        <div className="post-thumbnail">
                                            <img className="img-fluid" src={cfit_2} alt="post-thumbnail" />
                                        </div>
                                    </div>
                                    <div className="post-list-block">
                                        <div className="post-block-wrapper post-float">
                                            <div className="post-thumbnail">
                                                <a>
                                                    <img className="img-fluid" src={cfit_3} alt="post-thumbnail" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="post-block-wrapper post-float">
                                            <div className="post-thumbnail">
                                                <a>
                                                    <img className="img-fluid" src={cfit_4} alt="post-thumbnail" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="post-block-wrapper post-float" >
                                            <div className="post-thumbnail">
                                                <a>
                                                    <img className="img-fluid" src={cfit_6} alt="post-thumbnail" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default PartenariatDetail;