import { FaFacebook, FaLinkedin, FaWhatsapp } from 'react-icons/fa'
import '../style/footer.css'
import ssmt from '../assets/ssmt.jpg'
import ufhb from '../assets/ufhb.jpg'
import cfit from '../assets/logo_cfit.jpg'
import imertel from '../assets/imertel.jpg'
import aest from '../assets/aest.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/logo img.png'

const Footer = () => {

    return (
        <div>

            <footer className="padding_4x">
                <div className='flex'>
                    <section className='flex-content padding_1x'>
                        <h3>Equipe de Recherches</h3>
                        <a> Télécommunication, Electrique & Electronique </a>
                        <a>Matériaux, Energies Renouvelable & Innovation Technologie</a>
                        <a>Génie Mécanique, Mécatronique & Productique</a>
                    </section>
                    <section className='flex-content padding_1x'>
                        <div style={{ display: "flex" }}>
                            <a href="" target='_bank' style={{ cursor: "pointer" }}><img src={cfit} alt="logo" width={100} style={{ margin: "10px" }} /></a>
                            <a href="https://www.ufrssmt.edu.ci/" target='_bank' style={{ cursor: "pointer" }}><img src={ssmt} alt="" width={100} style={{ margin: "10px" }} /></a>

                            <a href="https://ufhb.appli.edu.ci/index.php" target='_bank' style={{ cursor: "pointer" }}><img src={ufhb} alt="" width={100} style={{ margin: "10px" }} /></a>
                        </div>
                        <div>
                            <img src={aest} alt="logo" width={100} style={{ margin: "10px" }} />
                            <img src={imertel} alt="logo" width={100} style={{ margin: "10px" }} />
                        </div>
                    </section>
                </div>
                <div className="flex">

                    <section className="flex-content padding_1x">
                        <h3>Plateaux techniques</h3>

                        <a><img src={logo} alt="logo" width={40} /> Atelier Génie Mécanique <span>(25)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Télécommunication <span>(12)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Electronique <span>(5)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Instrumentation <span>(22)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Electrotechnique <span>(8)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Robotique & Automatisme <span>(14)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Asservissement <span>(14)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Impression 3D <span>(14)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle RDM <span>(14)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Informatique <span>(14)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> Salle Fibre Optique <span>(14)</span></a>
                        <a><img src={logo} alt="logo" width={40} /> etc.... <span>(14)</span></a>


                    </section>
                    <section className="flex-content padding_1x">
                        <h3>Filières</h3>
                        <h5>Parcours initial professionnel</h5>
                        <a>EEAI : Electronique Electrotechnique Automatique & Informatique</a>
                        <a>EEM : Energétique Electro-Mécanique</a>
                        <a></a>
                        <h5>Parours Professionnel</h5>
                        <a >IME : Ingénierie Mécatronique & Energétique</a>
                        <a >IRST : Ingénierie Réseaux & Systèmes de Télécommunication</a>
                        <a ></a>
                        <h5>Master Spécialisé</h5>
                        <a>EST : Electronique & Systèmes de Télécommunication</a>
                        <a >PSA : Physique du Solide & Applications</a>
                    </section>
                    <section className="flex-content padding_1x">
                        <h3>Contactez nous</h3>
                        <a >82X5+5XF, Université Félix-Houphouët-Boigny, </a>
                        <a>Abidjan Côte d'Ivoire</a>
                        <br />
                        <h3 >Téléphone: </h3>
                        <a style={{ marginLeft: "22px" }}>05 56 14 00 71</a>
                        <br />

                        <h3 >Email: </h3>
                        <a style={{ marginLeft: "22px" }}>info@ufrssmt.edu.ci</a>

                    </section>

                </div>

                <div className="flex">
                    <section className="flex-content padding_1x">
                        <p>Copyright ©2023 Tous droits réservés | | Laboratoire de Technologie LabTech <a href='/Accueil/qui-sommes-nous/'>Qui sommes nous</a></p>
                    </section>
                    <section className="flex-content padding_1x">
                        <a href="https://www.facebook.com/profile.php?id=100064584196757" target='_bank'><FaFacebook /></a>
                        <a><FaLinkedin /></a>
                        <a><FaWhatsapp /></a>
                    </section>
                </div>
            </footer>

        </div>
    )
}

export default Footer