import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaPencilAlt } from "react-icons/fa";

const ModalProfilPhoto = ({ show, onHide, id }) => {
    const { handleSubmit, reset } = useForm()
    const [filePreview, setFilePreview] = useState(false);
    const [filePhoto, setFilePhoto] = useState(false);
    const [etatBoutonEnregistrer, setEtatBoutonEnregistrer] = useState(false);

    // Cette fonction est déclenchée lorsqu'un fichier est sélectionné
    const handleFileChange = (e) => {
        console.log(e)

        setEtatBoutonEnregistrer(true)

        const selectedFile = e.target.files[0];

        setFilePhoto(selectedFile)

        // Vérification que le fichier est une image
        if (selectedFile && selectedFile.type.startsWith('image')) {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);

            // Lecture du contenu du fichier
            reader.onloadend = () => {
                // Mise à jour du state avec l'URL du fichier
                setFilePreview(reader.result);
            };
        } else {
            // Gérer d'autres types de fichiers (vidéos, etc.) si nécessaire
            // Vous pouvez étendre cette logique pour d'autres types de fichiers
            setFilePreview(false);
        }
    };

    const modifierPhotoProfil = (data) => {
        
        updateExec(data)

        reset()
    }

    const updateExec = async (data) =>{
        let formData = new FormData()

        formData.append('file', filePhoto)
        formData.append('id', id)

        // console.log(formData.get('id'))

        try {

            await axios.put('https://labtech-bakoffice.ufrssmt.edu.ci/api/compte/new_photo_profil/', formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        )
            
        } catch (error) {
            console.log(error)
        }

        // console.log(response)
    }
    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Photo de profil
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div align="center">
                        {
                            filePreview && (
                                <div>
                                    {/* Affichage de l'aperçu de l'image */}
                                    <img src={filePreview} alt="File Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                </div>
                            )
                        }

                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <form onSubmit={handleSubmit(modifierPhotoProfil)}>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <div style={{padding:"5px",marginRight:"10px", border:"1px solid black", borderRadius:"8px",}}>
                                <label htmlFor="photoProfil" style = {{ display: "flex", alignItems: "center",cursor:"pointer" }}>Modifier <FaPencilAlt /></label>
                                <input type="file" id="photoProfil" accept="image/*" style={{ display: "none" }} onChange = {(e) => handleFileChange(e)} />
                            </div>
                            <div style={{padding:"5px",marginRight:"10px", border: `1px solid ${etatBoutonEnregistrer === false ? "rgb(212,212,212)":"blue"}`, color: etatBoutonEnregistrer === false ? "rgb(212,212,212)" : "blue", borderRadius:"8px", cursor: etatBoutonEnregistrer === false ? "text":"pointer"}}>
                                <label htmlFor="validerPhotoProfil" style={{cursor:etatBoutonEnregistrer ===false ? "text" : "pointer"}}>Enregistrer les modification</label>
                                <input type="submit" id="validerPhotoProfil" style={{display:"none"}}/>
                            </div>
                        </div>
                    </form>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default ModalProfilPhoto;