import Footer from "./Footer";

const QuiSommesNous = () =>{


    return (
        <div className="QuiSommesNous">
<section class="section-padding">
	<div class="container">
	<header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                        <a href="/accueil" style={{ cursor: "pointer" }}>Accueil</a>
                    </header>
		<div class="row">
			<div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
				<h2>Qui sommes nous</h2>
				<hr />
				<h3>Laboration de technologie (LabTech) : SSMT UFHB</h3>
				

				<p>LabTech est un pôle d'innovation technologique dédié à la recherche, au développement et à l'application de solutions avant-gardistes. Fondé sur des principes d'excellence scientifique et d'ingénierie de pointe, nous nous engageons à repousser les limites de la technologie pour créer un impact positif sur le monde qui nous entoure.</p>

				<h3 class="mt-5">Notre Mission</h3>
				<p>Notre mission est de concevoir, développer et mettre en œuvre des technologies de pointe qui résolvent des défis complexes et améliorent la vie quotidienne. Nous sommes passionnés par l'idée de transformer des idées novatrices en solutions tangibles qui ouvrent de nouvelles perspectives dans le domaine de la technologie.</p>

				<h3 class="mt-5">Nos Domaines d'Expertise</h3>
                
                <p>Au cœur de notre laboratoire, l'expertise se déploie dans divers domaines, notamment :</p>

				<ul class="info-list">
					<li>Intelligence artificielle et apprentissage automatique;</li>
					<li>Internet des objets (IoT) et objets connectés;</li>
					<li>Cyber-sécurité et protection des données;</li>
					<li>Robotique avancée et automatisation</li>
					<li>Solutions durables et énergies renouvelables</li>
				</ul>

				<h3 class="mt-5">Notre Approche Collaborative</h3>
				<p>Au LabTech, nous croyons en la puissance de la collaboration. Nous travaillons en étroite collaboration avec des partenaires industriels, des institutions académiques et des innovateurs indépendants pour stimuler l'innovation et favoriser un écosystème technologique dynamique.</p>

				<h3 class="mt-5">Engagés envers l'Excellence</h3>
				<p>Notre équipe diversifiée et multidisciplinaire est composée de chercheurs, d'ingénieurs et de professionnels passionnés qui partagent un engagement commun envers l'excellence. Nous croyons que chaque défi technologique est une opportunité de repousser les frontières du possible.</p>

				<p>Rejoignez-nous dans notre quête incessante de l'innovation technologique, où la créativité, la rigueur scientifique et l'impact positif se rencontrent pour façonner le futur.</p>
			</div>
		</div>
	</div>
</section>

<Footer/>
        </div>
    )
}

export default QuiSommesNous;