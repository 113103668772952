import { FaTags } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

const Tags = () => {
    const navigate = useNavigate()

    const handleTagePage = (path, categorie) => {
        navigate(path, { state: { categorie } })
        window.scrollTo(0, 0);
    }

    return (
        <div class="post-tags">
            <FaTags />   <span>Tags</span>
            <a style={{cursor: "pointer"}} onClick={() => handleTagePage('/catégorie/Actualités', 'Actualités')}>Actualités</a>
            <a style={{cursor: "pointer"}} onClick={() => handleTagePage('/catégorie/évènements', 'évènements')}>évènements</a>
            <a style={{cursor: "pointer"}} onClick={() => handleTagePage('/catégorie/Fêtes', 'Fêtes')}>Fêtes</a>
        </div>
    )
}

export default Tags;