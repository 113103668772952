import '../style/accueil.css'
import { FaBookAtlas, FaCode, FaUserLarge } from 'react-icons/fa6';
import { FaSearch, FaWindowClose } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import logo from '../assets/logo.png'
import logo_cfit from '../assets/logo_cfit.jpg'
import Actualite from '../composant/Actualite';
import ssmt from '../assets/ssmt.jpg'
import { useState } from 'react';
import User from './User';
import Challenge from '../composant/Challenge';
import { IoMdSchool } from 'react-icons/io';
import { HiOutlineUserGroup, HiViewGrid } from "react-icons/hi";
import NosFormations from '../composant/NosFormations';
import Partenariat from '../composant/Partenariat';
import NosAlumnis from '../composant/NosAlumnis';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Accueil = () => {

    //les etats
    const [etatActif, setEtatActif] = useState('Actualités')
    const [pageCurrent, setpageCurrent] = useState(<Actualite />)
    const [etatSliderBar, setEtatSliderBar] = useState("none");
    const {register, handleSubmit} = useForm()
    const navigate = useNavigate()

    const searchPage = (data)=>{
        navigate(`/recherche/${data.search}`)
        window.scroll(0,0)
    }

    const conteneurElementIcon = {
        color: "rgb(76, 158, 231)"
    }

    const actifConteneurElementIcon = {
        color: "blue",
        // borderTop: "1px solid rgba(0,0,0,0.4)",
        borderBottom: "1px solid rgba(0,0,0,0.2)",
        paddingBottom: "10px",
        fontSize: "20px",
    }

    //les fonctions
    const handleEtatActif = (element, path) => {
        setpageCurrent(element)
        setEtatActif(path)
        window.scrollTo(0, 0);
        setEtatSliderBar("none")
    }

    const handleSliderBarOpen = () => {
        setEtatSliderBar("initial")
    }

    const handleSliderBarClose = () => {
        setEtatSliderBar("none")
    }

    return (
        <div className="Accueil" style={{ position: "relative" }}>

            <div className="sideBarsCompte" style={{ display: etatSliderBar }}>

                <div className='elementSidebar'>
                    <div className='conteneurElementIcon' style={etatActif === 'Actualités' ? actifConteneurElementIcon : conteneurElementIcon} onClick={() => handleEtatActif(<Actualite />, 'Actualités')}>
                        <FaBookAtlas className='elementIcon' />
                        <div className='elementName'>Actualités</div>
                    </div>

                    <div className='conteneurElementIcon' style={etatActif === 'Nos Alumnis' ? actifConteneurElementIcon : conteneurElementIcon} onClick={() => handleEtatActif(<NosAlumnis />, 'Nos Alumnis')}>
                    <HiOutlineUserGroup className='elementIcon'/>
                        <div className='elementName'>Nos Alumnis</div>
                    </div>

                    <div className='conteneurElementIcon' style={etatActif === 'Nos Formations' ? actifConteneurElementIcon : conteneurElementIcon} onClick={() => handleEtatActif(<NosFormations />, 'Nos Formations')}>
                        <IoMdSchool className='elementIcon' />
                        <div className='elementName'>Nos Formations</div>
                    </div>               

                    <div className='conteneurElementIcon' style={etatActif === 'Partenariat' ? actifConteneurElementIcon : conteneurElementIcon} onClick={() => handleEtatActif(<Partenariat />, 'Partenariat')}>
                    <HiViewGrid className='elementIcon'/>
                        <div className='elementName'>Partenariat</div>
                    </div>
                    
                    <div className='conteneurElementIcon' style={etatActif === 'Challenge' ? actifConteneurElementIcon : conteneurElementIcon} onClick={() => handleEtatActif(<Challenge />, 'Challenge')}>
                        <FaCode className='elementIcon' />
                        <div className='elementName'>Challenge</div>
                    </div>

                    <div className='conteneurElementIcon' style={etatActif === 'Mon compte' ? actifConteneurElementIcon : conteneurElementIcon} onClick={() => handleEtatActif(<User />, 'Mon compte')}>
                        <FaUserLarge className='elementIcon' />
                        <div className='elementName'>Mon compte</div>
                    </div>
                </div>
            </div>



            <div className='conteneurHeader'>
                <a href="Accueil"><img src={logo} alt="logo site" className='imageSidebar' width={80}/></a>
                <a href="Accueil"><img src={logo_cfit} alt="logo site" className='imageSidebar' width={90}/></a>
                <div style={{ padding: "5px" }} className='searchEntete'>
                    <span style={{ fontWeight: "100" }}>Pages /</span><br />
                    <span style={{ fontSize: "22px" }}>{etatActif}</span>
                </div>

                <div className='userSearchContainer'>
                    <form onSubmit={handleSubmit(searchPage)}>
                    <input type="text" style={{ border: "1px solid white", padding: "5px", paddingLeft: "30px", borderRadius: "20px", width: "220px", backgroundColor: "rgba(0,0,0,0.2)" }} placeholder='Search...' className='searchEntete' {...register('search')}  required/>
                    <input type="submit" id='search' style={{display:"none"}}/>
                    <label htmlFor="search"><FaSearch className='iconSearch' style={{cursor: "pointer", top:"17px"}} /></label>
                    </form>
                    
                    
                    <span></span>

                    <span>
                        <FiMenu style={{ margin: "10px", display: etatSliderBar === "none" ? "initial" : "none", color: "rgb(76, 158, 231)" }} onClick={() => handleSliderBarOpen()} />
                        <FaWindowClose style={{ margin: "10px", display: etatSliderBar === "initial" ? "initial" : "none", color: "rgba(255, 68, 0, 0.763)" }} onClick={() => handleSliderBarClose()} />
                    </span>
                    <span className='userIconHeader' style={{cursor:"default"}}>
                    {/* <FaUserCircle style={{ fontSize: "25px" }} onClick={() => show === true ? setShow(false) : setShow(true)} /> */}
                    <img src={ssmt} width={30} alt="logo ssmt" />
                    </span>
                </div>
               
            </div>

            <div className='conteneurComposantsActifs' style={{ width: "100%" }}>
                {pageCurrent}
            </div>
        </div>
    )
}

export default Accueil;