import axios from "axios";
import { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";

const SearchPage = () => {

    const { search } = useParams()

    const [searchData, setSearchData] = useState([{}]);
    const navigate = useNavigate()

    useEffect(() => {

        const recuSearch = async () => {
            try {

                const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/search_page/${search}`)
               
                setSearchData(response.data['info'])

            } catch (error) {

                console.log(error)

            }
        }

        recuSearch()

    }, [search])


    const handlePage = (path, article) => {
        navigate(path, { state: { article: article } })
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <header style={{ backgroundColor: "#0e69b0", padding: "20px" , fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white"}}>
                        <a href="/accueil" style={{ cursor: "pointer", color:"white",fontSize: "18px", paddingLeft:"20px" }}>Accueil</a> <MdKeyboardDoubleArrowRight /> <span style={{ cursor: "pointer" }}>Recherche</span>
                    </header>
            <section className="error-404 section-padding bg-white" style={{ paddingTop: "10px" }}>
                <div className="container">
                    
                    <h2 className="mt-5">Résultats de recherche pour <span style={{ fontWeight: "bold", fontStyle: "italic" }}>{search}</span> </h2>
                    <hr />

                    <div className="row mt-5">

                        {
                            searchData.map((data, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6" key={index}>
                                        <div className="post-block-wrapper clearfix mb-5 mb-lg-0" onClick={() => handlePage(`/actualité/${data.titre}`, data._id)} style={{cursor: "pointer"}}>
                                            <div className="post-thumbnail">
                                                <a>
                                                    <img src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${data.file}`} className="img-fluid" alt="post-image" />
                                                </a>
                                            </div>
                                            <div className="post-content">
                                                <h2 className="post-title mt-3">
                                                    <a>{data.titre}</a>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {searchData.length === 0 && (
                            <div style={{ fontWeight: "bold", fontStyle: "italic", textAlign: "center" }}>
                                Aucun résultat trouvé
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default SearchPage;
