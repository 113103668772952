import axios from "axios";
import img_connexion from '../assets/auth.png'
import { useState } from "react";
import { Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import '../style/connexion.css';

const Connexion = () => {
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm();
    const [msgToast, setMsgToast] = useState(false);
    const [messageServeur, setMessageServeur] = useState("Mot de passe ou e-mail n'existe pas");

    const connexion = (data) => {
        const field = {
            email: data.email,
            mdp: data.mdp
        }

        auth(field)
    }

    const auth = async (field) => {
        try {
            const response = await axios.post('https://labtech-bakoffice.ufrssmt.edu.ci/api/user/connexion/', field,{headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}})
console.log(response)
            if (response.data['msg'] === "succès") {

                const id = response.data['id']
                localStorage.setItem('admin', id)
                navigate('/accueil', {state: {id: id}})

            } else if(response.data['msg'] === "Votre mail n'existe pas"){

                setMessageServeur("Votre mail n'existe pas")
                setMsgToast(true)

            } else if (response.data['msg'] === "Mot de passe incorrecte") {
                setMessageServeur("Mot de passe incorrecte")
                setMsgToast(true)
            }
            
            else {

                setMsgToast(true)

            }
            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="Connexion connexionPage" align="center">
            <img src={img_connexion} alt="logo connexion" className='iconDeux'/>
            {/* SESSION CONNEXION PAGE */}
            <div className='conteneurForm' >
                <form onSubmit={handleSubmit(connexion)}>
                    <div className='conteneurInput'>
                        <label htmlFor="email">E-mail ou numéro de téléphone : </label>
                        <input type="text" name='email' id='email' {...register('email')} />
                    </div>
                    <div className='conteneurInput'>
                        <label htmlFor="mdp">Mot de passe : </label>
                        <input type="password" name='mdp' id='mdp' {...register('mdp')} />
                    </div>
                    <div className='motDePasseOublie'>
                        Mot de passe oublié?
                    </div>
                    <div className='conteneurSubmit' style={{margin:"auto", cursor:"pointer"}}>
                        <label htmlFor="connexion" style={{ cursor:"pointer"}}>connexion</label>
                        <input type="submit" id='connexion' style={{ display: "none" }} />
                    </div>
                </form>
                <div style={{ textAlign: 'center', marginTop: "20px", marginLeft: "auto", cursor: "pointer", color:"blue", textDecoration: "underline"}} onClick={() => navigate('/inscription')}>
                    S'inscrire
                </div>

                <Toast style={{width:'100%'}} onClose={() => setMsgToast(false)} show={msgToast} delay={10000} autohide>
                    <Toast.Body style={{ backgroundColor: 'red', padding: "10px", textAlign: "center", color: "white" }}>
                        {messageServeur}
                    </Toast.Body>
                </Toast>

                <div className='separateur' style={{ textAlign: "center", marginTop: "20px", marginBottom: "0px" }}>
                    ------------------------------------
                </div>
                <div className='connexionRapide' align='center'>
                    <img src="./images/icons/icons-facebook.svg" alt="icon facebook" width={30} style={{ margin: "10px" }} />
                    <img src="./images/icons/iconGoogle.png" alt="icon google" width={30} style={{ margin: "10px" }} />
                    <img src="./images/icons/icons-linkedin.svg" alt="icon google" width={30} style={{ margin: "10px" }} />
                </div>
            </div>
            {/* FIN SESSION CONNEXION PAGE */}

            {/* <img src={img_connexion} alt="logo connexion" className='connexion pageImage'/> */}
            <br /><br />
        </div>
    )
}

export default Connexion;