import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const RecentActualite = () => {

    const navigate = useNavigate()
    
    const [articleRecent, setArticleRecent] = useState([{ file: "", fileDeux: "", categorie: "", titre: '', titreDeux: "", sousTitre: "", sousTitreDeux: "", description: "", descriptionDeux: "" }, { file: "", fileDeux: "", categorie: "", titre: '', titreDeux: "", sousTitre: "", sousTitreDeux: "", description: "", descriptionDeux: "" }, { file: "", fileDeux: "", categorie: "", titre: '', titreDeux: "", sousTitre: "", sousTitreDeux: "", description: "", descriptionDeux: "" }, { file: "", fileDeux: "", categorie: "", titre: '', titreDeux: "", sousTitre: "", sousTitreDeux: "", description: "", descriptionDeux: "" }, { file: "", fileDeux: "", categorie: "", titre: '', titreDeux: "", sousTitre: "", sousTitreDeux: "", description: "", descriptionDeux: "" }]);

    useEffect(() => {

        const recu_article = async () => {

            const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/recent_article/`)

            setArticleRecent(response.data['info'])
            console.log(response.data['info'])

        }

        recu_article()

    }, [])


    const handlePage = (path, article) => {
        navigate(path, { state: { article: article } })
        window.scrollTo(0, 0);
    }


    return (
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="sidebar sidebar-right">

                <div className="widget">
                    <h3 className="news-title">
                        <span>Actualités récentes</span>
                    </h3>

                    <div className="post-overlay-wrapper" style={{cursor: "pointer"}} onClick={() => handlePage(`/actualité/${articleRecent[0].titre}`, articleRecent[0]._id)}>
                        <div className="post-thumbnail">
                            <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleRecent[0].file}`} alt="post-thumbnail" />
                        </div>
                        <div className="post-content">
                            <a className="post-category white">{articleRecent[0].categorie}</a>
                            
                            <div className="post-meta white">
                                <span className="post-author">
                                    <a >{articleRecent[0].titre.substring(0, 100)}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="post-list-block">
                        <div className="post-block-wrapper post-float" style={{cursor: "pointer"}} onClick={() => handlePage(`/actualité/${articleRecent[1].titre}`, articleRecent[1]._id)}>
                            <div className="post-thumbnail">
                                <a>
                                    <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleRecent[1].file}`} alt="post-thumbnail" />
                                </a>
                            </div>
                            <div className="post-content">
                                <h2 className="post-title title-sm" style={{lineHeight:"18px"}}>
                                    <a  style={{fontSize:"12px", fontWeight:"100"}}>{articleRecent[1].titre}</a>
                                </h2>
                            </div>
                        </div>
                        <div className="post-block-wrapper post-float" style={{cursor: "pointer"}} onClick={() => handlePage(`/actualité/${articleRecent[2].titre}`, articleRecent[2]._id)}>
                            <div className="post-thumbnail">
                                <a>
                                    <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleRecent[2].file}`} alt="post-thumbnail" />
                                </a>
                            </div>
                            <div className="post-content">
                                <h2 className="post-title title-sm" style={{lineHeight:"18px"}}>
                                    <a style={{fontSize:"12px", fontWeight:"100"}}>{articleRecent[2].titre}</a>
                                </h2>
                            </div>
                        </div>
                        <div className="post-block-wrapper post-float" style={{cursor: "pointer"}} onClick={() => handlePage(`/actualité/${articleRecent[3].titre}`, articleRecent[3]._id)}>
                            <div className="post-thumbnail">
                                <a>
                                    <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleRecent[3].file}`} alt="post-thumbnail" />
                                </a>
                            </div>

                            <div className="post-content">
                                <h2 className="post-title title-sm" style={{lineHeight:"18px"}}>
                                    <a style={{fontSize:"12px", fontWeight:"100"}}>{articleRecent[3].titre}</a>
                                </h2>
                            </div>
                        </div>
                        <div className="post-block-wrapper post-float" style={{cursor: "pointer"}} onClick={() => handlePage(`/actualité/${articleRecent[4].titre}`, articleRecent[4]._id)}>
                            <div className="post-thumbnail">
                                <a>
                                    <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleRecent[4].file}`} alt="post-thumbnail" />
                                </a>
                            </div>

                            <div className="post-content">
                                <h2 className="post-title title-sm" style={{lineHeight:"18px"}}>
                                    <a style={{fontSize:"12px", fontWeight:"100"}}>{articleRecent[4].titre}</a>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default RecentActualite;