import { useState } from "react";
import ModalArticle from "../composant/modals/ModalArticle";
import ModalChallenge from "../composant/modals/ModalChallenge";
import ModalJobs from "../composant/modals/ModalJobs";
import { useEffect } from "react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PageMessageVisiteur = () => {

    const navigate = useNavigate()

    const [articleDisponible, setArticleDisponible] = useState([{ message: "" }]);
    const [displayNon, setDisplayNon] = useState();

    useEffect(() => {
        const recu = async () => {
            try {

                const response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/admin/afficher_message_visiteur/')
                setArticleDisponible(response.data['info'])

            } catch (error) {
                console.log(error)
            }
        }

        recu()
    }, [])

    const handleDelete = (data, index) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Voulez-vous supprimer cet article?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => {
                        deleteConfirm(data)
                        setDisplayNon(index)
                    }
                },
                {
                    label: 'non',
                    // onClick: () => alert('You clicked No')
                }
            ]
        });
    }

    const deleteConfirm = async (data) => {
        const response = await axios.delete(`https://labtech-bakoffice.ufrssmt.edu.ci/api/admin/delete_message/${data}`)
        console.log(response)
    }


    return (
        <div className='PageAccueil'>

            <div className="content">

                <div className="content-main">
                    <div className="card-grid">

                        {
                            articleDisponible.map((data, index) => {
                                return (
                                    <article className="card" key={index} style={{ display: displayNon === index && "none" }}>
                                        <div className="card-body">
                                            <p>
                                                <label className="toggle" style={{ cursor: "pointer", color: "red", fontSize: "12px", position: "absolute", right: "10px" }}>
                                                    <MdDelete onClick={() => handleDelete(data._id, index)} /> suprimer
                                                </label><br /><br />
                                                Nom : <span style={{ color: "black" }}>{data.nom}</span> <br />
                                                Email : <a href={`mailto:${data.email}`}>{data.email}</a><br />
                                                Sujet : <span style={{ color: "black" }}>{data.sujet}</span><br />

                                                Date: <span style={{ color: "black", fontWeight: "bold" }}>{data.temps}</span>
                                            </p>
                                            <p>
                                                Message: <br />
                                                <span style={{ color: "black", marginLeft: "20px" }}>{data.message}</span>
                                            </p>
                                        </div>
                                    </article>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default PageMessageVisiteur;
