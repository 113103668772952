import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import img from '../assets/img_labtech/2.jpg'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import RecentActualite from './RecentActualite';
import Footer from './Footer';

const CategorieArticle = () => {

    const { state } = useLocation()
    const navigate = useNavigate()
    const [categorieData, setCategorieData] = useState([{ file: "", fileDeux: "", categorie: "", titre: '', titreDeux: "", sousTitre: "", sousTitreDeux: "", description: "", descriptionDeux: "" }]);



    useEffect(() => {

        const recu_categorie = async () => {
            const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/afficher_categorie/${state.categorie}`)
            setCategorieData(response.data['info'])
            console.log(response)
        }

        recu_categorie()

    }, [state])

    const handlePage = (path, article) => {
        navigate(path, { state: { article: article } })
        window.scrollTo(0, 0);
    }


    return (
        <div>

            <div class="breadcrumb-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <ol class="breadcrumb" style={{ alignItems: "center" }}>
                                <li>
                                    <a href="/accueil" style={{ cursor: "pointer" }}>Accueil</a>
                                </li>
                                <MdOutlineKeyboardArrowRight />
                                <li>{state.categorie}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>


            <section class="block-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div class="block category-listing">
                                <h3 class="news-title"><span>{state.categorie}</span></h3>
                            </div>
                            <div class="row">


                                {
                                    categorieData.map((data, index) => {
                                        return (
                                            <div class="col-md-6 col-sm-6" key={index}>
                                                <div class="post-block-wrapper post-grid-view clearfix" style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${data.titre}`, data._id)}>
                                                    <div class="post-thumbnail">
                                                        <a>
                                                            <img class="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${data.file}`} alt="post-thumbnail" />
                                                        </a>
                                                    </div>
                                                    <div class="post-content">
                                                        <h2 class="post-title mt-3">
                                                            <a>{data.titre}</a>
                                                        </h2>
                                                        <p>
                                                            {data.sousTitre.substring(0, 100)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            {/* <nav aria-label="pagination-wrapper" class="pagination-wrapper">
                                <ul class="pagination justify-content-center">

                                    <li class="page-item">
                                        <a class="page-link" aria-label="Next">
                                            <span class="">Suivant</span>
                                            <span aria-hidden="true"><i class="fa fa-angle-double-right ml-2"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav> */}
                        </div>
                        {/* article recent */}
                        <RecentActualite />
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default CategorieArticle;