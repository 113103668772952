import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

//importation des css
import '../../style/modal/modalExperience.css'
import axios from "axios";
import { useState } from "react";

const ModalChallenge = ({ show, onHide, id }) => {

    const { register, handleSubmit, reset } = useForm()
    const [toast, setToast] = useState(false);

    const newChallenge = (data) => {

        const field = {
            id: id,
            domaine: data.domaine,
            sujet: data.sujet
        }

        addChallenge(field)
        setToast(true)

        reset()
    }

    const addChallenge = async (data) => {
        
        try {
            
            await axios.post('https://labtech-bakoffice.ufrssmt.edu.ci/api/challenge/new_challenge/', data,{headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}})
            
        } catch (error) {
            console.log(error)
        }

    }

    const closeModal = ()=>{
        reset()
        onHide(false)
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nouveau challenge
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(newChallenge)}>
                            <div className="inputZone">
                                <label htmlFor="">Domaine</label>
                                <select {...register('domaine')} >
                                    <option value="Information">Informatique</option>
                                    <option value="Electronique">Electronique</option>
                                    <option value="Automatisme">Automatisme</option>
                                    <option value="Electrotechnique">Electrotechnique</option>
                                    <option value="Réseaux électrique">Réseaux électrique</option>
                                    <option value="Télécom">Télécom</option>
                                </select>
                            </div>
                            <br />
                            <div className="inputZone">
                                <label htmlFor="">Sujet</label>
                                <textarea id=""  {...register('sujet')} cols="30" rows="5"></textarea>
                            </div>
                            <br />
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <div className="inputZoneSubmit" onClick={() => closeModal()}>
                                    <label htmlFor="" style={{backgroundColor:"red", marginRight:"20px", width:"160px"}}>Annuler</label>
                                </div>
                            <div className="inputZoneSubmit">
                                <label htmlFor="envoyer" style={{width:"160px"}}>Publier</label>
                                <input type="submit" id="envoyer" style={{ display: 'none' }} />
                            </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalChallenge;