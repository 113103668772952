import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from './Footer';


const VoirPhotoGalerie = () => {

    const navigate = useNavigate()
    const dossier = useParams()

    let title = "";
    let description = ""

    if (dossier.type === "cfit") {
        title = "Projet CFIT III"
        description = <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <div class="tm-bg-gray tm-video-details">
                <p class="mb-4">
                    <h3>Projet CFIT III</h3> <br />
                    Un enseignement technique superieur en Afrique pour une main d'oeuvre technique et innovante
                </p>
                <div class="mb-4 d-flex flex-wrap">
                    <div class="mr-4 mb-2">
                        <div class="tm-text-gray-dark">Objectifs : </div>
                        <ul>
                            <li>Evaluer et anticiper les compétences</li>
                            <li>Concevoir des programmes d'enseignement de qualité</li>
                            <li>Favoriser l'apprentissage en milieu de travail et les compétences entrepreneuriales</li>
                            <li>Consolider les partenariats entre établissements d'enseignement superieur et l'industrie</li>
                            <li>Réviser les méthodes d'évaluation entre l'offre et la demande des compétences</li>
                        </ul>
                    </div>
                    <div class="mr-4 mb-2">
                        <div class="tm-text-gray-dark">Contact : </div>
                        <ul>
                            <li><a href="tel:002250102415034">01 02 41 50 34</a></li>
                            <li><a href="tel:002250142028686">01 42 02 86 86</a></li>
                            <li><a href="tel:002250747656034">07 47 65 60  34</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    }

    // Journée porte ouverte
    if (dossier.type === "journee_porte_ouverte") {
        title = "Journée porte ouverte"
        description = <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <div class="tm-bg-gray tm-video-details">
                <p class="mb-4">
                    <h3>Journée porte ouverte</h3> <br />
                    Que vous soyez un étudiant curieux, un professionnel à la recherche de nouvelles opportunités ou simplement quelqu'un désireux d'en savoir plus sur notre domaine, cette journée portes ouvertes est une occasion parfaite pour découvrir ce que nous offrons.
                </p>
                <div class="mb-4 d-flex flex-wrap">
                    <div class="mr-4 mb-2">
                        <div class="tm-text-gray-dark">Que pouvez-vous attendre </div>
                        <ul>
                            <li><strong>Visites Guidées</strong> : Explorez nos installations de pointe et découvrez les coulisses de notre organisation grâce à nos visites guidées.</li>
                            <li><strong>Présentations et Démonstrations</strong> : Assistez à des présentations captivantes et à des démonstrations interactives mettant en lumière nos projets les plus innovants.</li>
                            <li><strong>Ateliers et Activités</strong> : Participez à des ateliers pratiques et à des activités ludiques pour mieux comprendre notre secteur d'activité et développer de nouvelles compétences.</li>
                        </ul>
                    </div>
                    <div class="mr-4 mb-2">
                        <div class="tm-text-gray-dark">Contact : </div>
                        <ul>
                            {/* <li><a href="tel:002250102415034">01 02 41 50 34</a></li>
                            <li><a href="tel:002250142028686">01 42 02 86 86</a></li>
                            <li><a href="tel:002250747656034">07 47 65 60  34</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    }

    // Interview alumni
    if (dossier.type === "interview_alumni") {
        title = "Interview des alumnis"
        description = <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <div class="tm-bg-gray tm-video-details">
                <p class="mb-4">
                    <h3>Interview des alumnis</h3> <br />
                    
Les interviews avec d'anciens élèves, également appelées interviews d'alumni, sont des conversations qui mettent en lumière les expériences, les réalisations et les perspectives des anciens élèves d'une école, d'une université ou d'une organisation. Ces entretiens offrent souvent un aperçu précieux sur la façon dont les anciens élèves ont utilisé leurs connaissances et leurs expériences pour façonner leur carrière et leur vie après avoir quitté l'établissement.
                </p>
                <div class="mb-4 d-flex flex-wrap">
                    <div class="mr-4 mb-2">
                        <div class="tm-text-gray-dark">Objectifs : </div>
                        <ul>
                            <li><strong>Comprendre leurs expériences après l'école</strong> : Explorer les expériences professionnelles, les réussites, les défis et les leçons apprises depuis leur départ de l'établissement.</li>
                            <li><strong>Conseils et perspectives</strong> : Recueillir des conseils, des astuces et des perspectives sur la transition de la vie étudiante à la vie professionnelle, ainsi que sur le développement de carrière.</li>
                            <li><strong>Motivation et inspiration</strong> : Inspirer les étudiants actuels en partageant des histoires de réussite et en mettant en lumière les possibilités offertes par leur formatio</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    }


// DINER GALA
    if (dossier.type === "dine_gala") {
        title = "Diner gala d'excellence"
        description = <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <div class="tm-bg-gray tm-video-details">
                <p class="mb-4">
                    <h3>Diner gala d'excellence</h3> <br />               
Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe excepturi omnis natus ut sint? Eveniet eum in facere impedit atque? Omnis aperiam aliquam illo. Recusandae sunt veniam voluptas modi debitis?
                </p>
                <div class="mb-4 d-flex flex-wrap">
                    <div class="mr-4 mb-2">
                        <div class="tm-text-gray-dark">Objectifs : </div>
                        <ul>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    }
    
// JOURNEE SPORTIVE
if (dossier.type === "journee_sportive") {
    title = "Journée sportive"
    description = <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
        <div class="tm-bg-gray tm-video-details">
            <p class="mb-4">
                <h3>Journée sportive</h3> <br />               
Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe excepturi omnis natus ut sint? Eveniet eum in facere impedit atque? Omnis aperiam aliquam illo. Recusandae sunt veniam voluptas modi debitis?
            </p>
            <div class="mb-4 d-flex flex-wrap">
                <div class="mr-4 mb-2">
                    <div class="tm-text-gray-dark">Objectifs : </div>
                    <ul>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
}

// SALLE INFORMATIQUE
if (dossier.type === "salle_informatique") {
    title = "Salle informatique"
    description = <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
        <div class="tm-bg-gray tm-video-details">
            <p class="mb-4">
                <h3>Salle informatique</h3> <br />               
Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe excepturi omnis natus ut sint? Eveniet eum in facere impedit atque? Omnis aperiam aliquam illo. Recusandae sunt veniam voluptas modi debitis?
            </p>
            <div class="mb-4 d-flex flex-wrap">
                <div class="mr-4 mb-2">
                    <div class="tm-text-gray-dark">Objectifs : </div>
                    <ul>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
}

    return (
        <div>
            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span onClick={() => navigate('/accueil')} style={{ cursor: "pointer" }}>Accueil</span> <MdKeyboardDoubleArrowRight /> <span style={{ cursor: "pointer" }}>photo</span>
            </header>
            <div class="container-fluid tm-container-content tm-mt-60">
                <div class="row mb-4">
                    <h2 class="col-12 tm-text-primary">{title}</h2>
                </div>
                <div class="row tm-mb-90">
                    <div class="col-xl-8 col-lg-7 col-md-6 col-sm-12">
                        <img src={`/assets/${dossier.type}/1.jpg`} alt="Image" class="img-fluid" />
                    </div>
                    {description}
                </div>
                <div class="row mb-4">
                    <h2 class="col-12 tm-text-primary">
                        Photos associées
                    </h2>
                </div>
                <div class="row mb-3 tm-gallery">
                    
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                        <figure class="effect-ming tm-video-item">
                            <img src={`/assets/${dossier.type}/2.jpg`} alt="Image" class="img-fluid" />
                            <figcaption class="d-flex align-items-center justify-content-center">
                                <h2></h2>
                                <a href="#">View more</a>
                            </figcaption>
                        </figure>
                        
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                        <figure class="effect-ming tm-video-item">
                            <img src={`/assets/${dossier.type}/3.jpg`} alt="Image" class="img-fluid" />
                            <figcaption class="d-flex align-items-center justify-content-center">
                                <h2></h2>
                                <a href="#">View more</a>
                            </figcaption>
                        </figure>
                        
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                        <figure class="effect-ming tm-video-item">
                            <img src={`/assets/${dossier.type}/4.jpg`} alt="Image" class="img-fluid" />
                            <figcaption class="d-flex align-items-center justify-content-center">
                                <h2></h2>
                                <a href="#">View more</a>
                            </figcaption>
                        </figure>
                       
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                        <figure class="effect-ming tm-video-item">
                            <img src={`/assets/${dossier.type}/5.jpg`} alt="Image" class="img-fluid" />
                            <figcaption class="d-flex align-items-center justify-content-center">
                                <h2></h2>
                                <a href="#">View more</a>
                            </figcaption>
                        </figure>
                        
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                        <figure class="effect-ming tm-video-item">
                            <img src={`/assets/${dossier.type}/7.jpg`} alt="Image" class="img-fluid" />
                            <figcaption class="d-flex align-items-center justify-content-center">
                                <h2></h2>
                                <a href="#">View more</a>
                            </figcaption>
                        </figure>
                        
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                        <figure class="effect-ming tm-video-item">
                            <img src={`/assets/${dossier.type}/8.jpg`} alt="Image" class="img-fluid" />
                            <figcaption class="d-flex align-items-center justify-content-center">
                                <h2></h2>
                                <a href="#">View more</a>
                            </figcaption>
                        </figure>
                        
                    </div>
                </div>
                {/* <!-- row --> */}
            </div>
            {/* <!-- container-fluid, tm-container-content --> */}
            <Footer/>
        </div>
    )
}

export default VoirPhotoGalerie;