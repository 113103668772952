import {FaFacebook, FaLinkedin } from 'react-icons/fa';
import '../style/lireActualite.css'
import Footer from '../composant/Footer'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import ima_1 from '../assets/img_labtech/1.jpg'
import { useNavigate } from 'react-router-dom';


const SalllesMachine = () => {

    const navigate = useNavigate()

    return (
        <div className="lireActualite">

            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span onClick={() => navigate('/accueil')} style={{ cursor: "pointer" }}>Accueil</span> <MdKeyboardDoubleArrowRight /> <span style={{ cursor: "pointer" }}>Actualités</span>
            </header>

            <section class="single-block-wrapper section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div class="single-post">
                                <div class="post-header mb-5">
                                    <a class="post-category" href="post-category-1.html">Health</a>
                                    <h2 class="post-title">
                                        First Look At Self-Portrait's Autumn Collection
                                    </h2>
                                    <p>It was a cheerful prospect. I asked Perry what he thought about it; but he only shrugged his shoulders and continued a longwinded prayer he had been at for some time. He was wont to say that the only redeeming feature of our captivity was the ample time it gave him for the improvisation of prayers</p>
                                </div>
                                <div class="post-body">
                                    <div class="post-featured-image">
                                        <img src={ima_1} class="img-fluid" alt="featured-image" />
                                    </div>
                                    <div class="entry-content">
                                        <p>
                                            It was a cheerful prospect. I asked Perry what he thought about it; but he only shrugged his shoulders and continued a longwinded prayer he had been at for some time. He was wont to say that the only redeeming feature of our captivity was the ample time it gave him for the improvisation of prayers—it was becoming an obsession with him. The Sagoths had begun to take notice of his habit of declaiming throughout entire marches. One of them asked him what he was saying—to whom he was talking. The question gave me an idea, so I answered quickly before Perry could say anything.
                                        </p>
                                        <h2>Perfect design & code delivered to you</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Repellat sapiente neque iusto praesentium adipisci itaque error, commodi laborum doloremque. Esse?
                                        </p>
                                        <div class="media mb-4 single-media">
                                            <img src={ima_1} alt="post-ads" class="img-fluid mr-4" />
                                            <div class="media-body">
                                                <p>
                                                    Oblique facilisi vix ei, quo ignota appetere lucilius at. Apeirian voluptatibus ius ei, an periculis imperdiet consequat sea. His ea everti placerat. Ad mea utroque convenire, an quo reque aperiam, has et unum cibo adipiscing. Brute instructior te vix, consequat definitiones conclusionemque et usu, et per idque quaerendum. Id pro ridens appareat, vim in verear pertinacia.
                                                </p>
                                            </div>
                                        </div>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde cum delectus exercitationem
                                            natus quidem enim error suscipit. Iure cupiditate nobis quaerat consectetur! Vero aliquam,
                                            amet ipsum ullam reiciendis nostrum voluptate accusantium provident ut blanditiis incidunt.
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates ab ratione animi nobis in et consequatur
                                            earum modi repellendus, qui, non debitis pariatur tempora consequuntur!
                                        </p>
                                    </div>

                                    <div class="share-block  d-flex justify-content-between align-items-center border-top border-bottom mt-5">
                                        <div class="post-tags">
                                            <span>Tags</span>
                                            <a href="post-category-2.html">Health</a>
                                            <a href="post-category-2.html">Game</a>
                                            <a href="post-category-2.html">Tour</a>
                                        </div>

                                       
                                    </div>
                                </div>
                            </div>

                            <nav class="post-navigation clearfix">
                                <div class="previous-post">
                                    <a href="single-post.html">
                                        <h6 class="text-uppercase">Précédent</h6>
                                        <h3>
                                            Intel’s new smart glasses actually look good
                                        </h3>
                                    </a>
                                </div>
                                <div class="next-post">
                                    <a href="single-post.html">
                                        <h6 class="text-uppercase">Suivant</h6>

                                        <h3>
                                            Free Two-Hour Delivery From Whole Foods
                                        </h3>
                                    </a>
                                </div>
                            </nav>
                            <div class="author-block">
                                <div class="author-thumb">
                                    <img src={ima_1} alt="author-image" />
                                </div>
                                <div class="author-content">
                                    <h3><a href="author.html">Lint Handson</a></h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit quod molestias delectus illum quisquam.
                                        Quasi iure perferendis suscipit officiis dicta!
                                    </p>

                                    <div class="authors-social">
                                        <a href="#">
                                            <FaFacebook style={{fontSize:"22px", color:"rgba(0,0,255,0.5)"}}/>
                                        </a>

                                        <a href="#">
                                            <FaLinkedin style={{fontSize:"22px", color:"cadetblue"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            

                            <div id="comments" class="comments-block block">
                                <h3 class="news-title">
                                    <span>Commentaires</span>
                                </h3>
                                <ul class="all-comments">
                                    <li>
                                        <div class="comment">
                                            <img class="commented-person" alt="" src={ima_1} />
                                            <div class="comment-body">
                                                <div class="meta-data">
                                                    <span class="commented-person-name">Jack Anderson</span>
                                                    <span class="comment-hour d-block"><i class="fa fa-clock-o mr-2"></i>February 6, 2019 at 12:20 pm</span>
                                                </div>
                                                <div class="comment-content">
                                                    <p>
                                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dicta perferendis asperiores
                                                        voluptatum saepe repellat atque obcaecati dolores nulla, explicabo harum inventore
                                                        sed corrupti molestiae aspernatur.
                                                    </p>
                                                </div>
                                                <div class="text-left">
                                                    <a class="comment-reply" href="#"><i class="fa fa-reply"></i> Répondre</a>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="comments-reply">
                                            <li>
                                                <div class="comment">
                                                    <img class="commented-person" alt="" src={ima_1} />
                                                    <div class="comment-body">
                                                        <div class="meta-data">
                                                            <span class="commented-person-name">Jhonny American</span>
                                                            <span class="comment-hour d-block"><i class="fa fa-clock-o mr-2"></i>March 9, 2019 at 12:20 pm</span>
                                                        </div>
                                                        <div class="comment-content">
                                                            <p>
                                                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui expedita magnam ea
                                                                tempora consectetur fugit dolorum numquam at obcaecati voluptatibus.
                                                            </p>
                                                        </div>
                                                        <div class="text-left">
                                                            <a class="comment-reply" href="#"><i class="fa fa-reply"></i> Répondre</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="comment last">
                                            <img class="commented-person" alt="" src={ima_1} />
                                            <div class="comment-body">
                                                <div class="meta-data">
                                                    <span class="commented-person-name">Vicky Fong</span>
                                                    <span class="comment-hour d-block"><i class="fa fa-clock-o mr-2"></i>June 6, 2019 at 12:20 pm</span>
                                                </div>
                                                <div class="comment-content">
                                                    <p>
                                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, ipsa dolor exercitationem
                                                        culpa obcaecati illo veniam voluptatem perferendis hic nisi tempora omnis, mollitia unde doloribus.
                                                    </p>
                                                </div>
                                                <div class="text-left">
                                                    <a class="comment-reply" href="#"><i class="fa fa-reply"></i> Répondre</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="comment-form ">
                                <h3 class="title-normal">Laisser une réponse </h3>
                                <p class="mb-4">Your email address will not be published. Required fields are marked *</p>
                                <form role="form">
                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input class="form-control" name="name" id="name" placeholder="Name" type="text" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input class="form-control" name="email" id="email" placeholder="Email" type="email" required />
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <textarea class="form-control required-field" id="message" placeholder="Messege" rows="8" required></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <button class="comments-btn btn btn-primary " type="submit">Poster un commentaire</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div class="sidebar sidebar-right">
                               
                                <div class="widget">
                                    <h3 class="news-title">
                                        <span>Actualités récentes</span>
                                    </h3>

                                    <div class="post-overlay-wrapper">
                                        <div class="post-thumbnail">
                                            <img class="img-fluid" src={ima_1} alt="post-thumbnail" />
                                        </div>
                                        <div class="post-content">
                                            <a class="post-category white" href="post-category-1.html">Fashion</a>
                                            <h2 class="post-title">
                                                <a href="single-post.html">First Look At Self-Portrait's Autumn Collection...</a>
                                            </h2>
                                            <div class="post-meta white">
                                                <span class="posted-time"><i class="fa fa-clock-o mr-1"></i>17 jun,2019</span>
                                                <span> by </span>
                                                <span class="post-author">
                                                    <a href="author.html">Jammy Anderson</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="post-list-block">
                                        <div class="post-block-wrapper post-float ">
                                            <div class="post-thumbnail">
                                                <a href="single-post.html">
                                                    <img class="img-fluid" src={ima_1} alt="post-thumbnail" />
                                                </a>
                                            </div>
                                            <div class="post-content">
                                                <h2 class="post-title title-sm">
                                                    <a href="single-post.html">Apple HomePod review: locked in</a>
                                                </h2>
                                                <div class="post-meta">
                                                    <span class="posted-time"><i class="fa fa-clock-o mr-1"></i>2 hours ago</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post-block-wrapper post-float">
                                            <div class="post-thumbnail">
                                                <a href="single-post.html">
                                                    <img class="img-fluid" src={ima_1} alt="post-thumbnail" />
                                                </a>
                                            </div>
                                            <div class="post-content">
                                                <h2 class="post-title title-sm">
                                                    <a href="single-post.html">Intel’s new smart glasses actually look good</a>
                                                </h2>
                                                <div class="post-meta">
                                                    <span class="posted-time"><i class="fa fa-clock-o mr-1"></i> 15 March , 2019</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post-block-wrapper post-float">
                                            <div class="post-thumbnail">
                                                <a href="single-post.html">
                                                    <img class="img-fluid" src={ima_1} alt="post-thumbnail" />
                                                </a>
                                            </div>

                                            <div class="post-content">
                                                <h2 class="post-title title-sm">
                                                    <a href="single-post.html">Free Two-Hour Delivery From Whole Foods</a>
                                                </h2>
                                                <div class="post-meta">
                                                    <span class="posted-time"><i class="fa fa-clock-o mr-1"></i> 5 hours ago</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post-block-wrapper post-float">
                                            <div class="post-thumbnail">
                                                <a href="single-post.html">
                                                    <img class="img-fluid" src={ima_1} alt="post-thumbnail" />
                                                </a>
                                            </div>

                                            <div class="post-content">
                                                <h2 class="post-title title-sm">
                                                    <a href="single-post.html">Here's How To Get Free Pizza On</a>
                                                </h2>
                                                <div class="post-meta">
                                                    <span class="posted-time"><i class="fa fa-clock-o mr-1"></i> 17 June, 2019</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default SalllesMachine;