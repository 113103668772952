import { Route, Routes } from 'react-router-dom';
import './App.css';
import Accueil from './screens/Accueil';
import Connexion from './screens/Connexion'
import Inscription from './screens/Inscription'
import LireActualite from './composant/LireActualite';
import PresentationLabTech from './composant/PresentationLabTech';
import SallesMachine from './pages/SalllesMachine';
import CategorieArticle from './composant/CategorieArticle';
import VoirPhotoGalerie from './composant/VoirPhotoGalerie';
import JobPostDetail from './composant/JobPostDetail';
import InfoAlumni from './composant/InfoAlumni';
import QuiSommesNous from './composant/QuiSommesNous';
import Dashboard from './screens/Dashboard';
import PageLireArticle from './Admin/PageLireArticle';
import PageProfil from './Admin/PageProfil';
import PageDetailJobs from './Admin/PageDetailJobs';
import AnswerChallenge from './composant/AnswerChallenge';
import VoirReponseChallenge from './composant/VoirReponseChallenge';
import PageDetailChallenge from './Admin/PageDetailChallenge';
import ErrorPage from './composant/ErrorPage';
import SearchPage from './composant/SearchPage';
import PartenariatDetail from './composant/PartenarialDetail';

function App() {
  return (
    <div className="App">
      <Routes >
        {/* <Route path='/connexion' element={<Connexion /> } /> */}
        <Route path='/dashboard' element={<Dashboard /> } />
        <Route path='/' element={<Accueil /> } />
        <Route path='/accueil' element={<Accueil /> } />
        <Route path='/actualité/:titre' element={<LireActualite /> } />
        <Route path='/actualité/admin/:titre' element={<PageLireArticle /> } />
        <Route path='/detaillé profil/:utilisateur' element={<PageProfil/> } />
        <Route path='/Accueil/laboratoire/présentation-du-laboratoire-de-technologie-université-cocody' element={<SallesMachine /> } />
        <Route path='/connexion' element={<Connexion /> } />
        <Route path='/inscription' element={<Inscription /> } />
        <Route path='/catégorie/:type' element={<CategorieArticle /> } />
        <Route path='/voir_photo/:type' element={<VoirPhotoGalerie/> } />
        <Route path='/Accueil/job-a-pouvoir/:type/:id' element={<JobPostDetail/> } />
        <Route path='/dashboard/job-a-pouvoir/:type/:id' element={<PageDetailJobs/> } />
        <Route path='/Accueil/compte-utilisateur-alumni/' element={<InfoAlumni/> } />
        <Route path='/Accueil/qui-sommes-nous/' element={<QuiSommesNous/> } />
        <Route path='/Challenge/repondre-au-challenge/:type' element={<AnswerChallenge/> } />
        <Route path='/admin/repondre-au-challenge-info/:type/:id' element={<VoirReponseChallenge/> } />
        <Route path='/admin/repondre-au-challenge-info-dashboard/:type/:id' element={<PageDetailChallenge/> } />
        <Route path='/Accueil/visite-guider-laboratoire-de-technologie-université-Felix-houphout-Boigny' element={<PresentationLabTech />}/>
        <Route path='/recherche/:search' element={<SearchPage />} />
        <Route path='/partenariat/laboratoire-technologie-et-projet-cfit-iii' element={<PartenariatDetail />} />

        {/* ERREUR 404 */}
        <Route path='*' element={<ErrorPage />}/>
      </Routes >
      
    </div>
  );
}

export default App;
