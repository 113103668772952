import '../style/spinnerCard.css'

const SpinnerCard = () => {

    return (
        <>
            <div class="spinnerCard">Loading&#8230;</div>
        </>
    )
}

export default SpinnerCard;