import axios from "axios";
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from "react-router-dom";

const PageNosEtudiants = () => {

    const [nosEtudiants, setNosEtudiants] = useState([{}]);
    const [displayNon, setDisplayNon] = useState();
    const navigate = useNavigate()

    useEffect(() => {
        const recu = async () => {

            try {

                const response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/admin/afficher_nos_etudiants_admin/')
                // console.log(response)
                setNosEtudiants(response.data['info'])

            } catch (error) {
                console.log(error)
            }

        }

        recu()
    }, [])

    const handleDelete = (data, index) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Voulez-vous supprimer le compte?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => {
                        deleteConfirm(data)
                        setDisplayNon(index)
                    }
                },
                {
                    label: 'non',
                    // onClick: () => alert('You clicked No')
                }
            ]
        });
    }

    const deleteConfirm = async (data) => {
        const response = await axios.delete(`https://labtech-bakoffice.ufrssmt.edu.ci/api/admin/delete_profil/${data}`)
        console.log(response)
    }

    return (
        <div>
            <div class="content">
                {/* <div class="content-panel">
                
                    <div class="vertical-tabs">
                        <a class="active">Tous</a>
                        <a >compte activé</a>
                        <a >compte desactivé</a>
                    </div>
                </div> */}
                <div class="content-main">
                    <div class="card-grid">
                        {
                            nosEtudiants.map((data, index) => {
                                return (
                                    <article className="card" key={index} style={{ display: displayNon === index && "none" }}>
                                        <div className="card-header">
                                            <div>
                                                <h6>{data.nom}</h6>
                                            </div>
                                            <label className="toggle" style={{ cursor: "pointer", color: "red", fontSize: "22px" }}>
                                                <MdDelete onClick={() => handleDelete(data._id, index)} />
                                            </label>
                                        </div>
                                        <div className="card-body">
                                            E-mail : <span style={{ fontWeight: "bold", marginLeft: "20px" }}>{data.email}</span><br />
                                            Contact : <span style={{ fontWeight: "bold", marginLeft: "12px" }}>{data.phone}</span><br />
                                            Filière : <span style={{ fontWeight: "bold", marginLeft: "23px" }}>{data.filiere}</span><br />
                                            Promotion : <span style={{ fontWeight: "bold", marginLeft: "12px" }}>{data.promotion}</span>
                                        </div>
                                        <div className="card-footer">
                                            <a style={{ cursor: "pointer" }} onClick={() => navigate(`/detaillé profil/${data.nom}`, { state: { id: data._id } })}>Voir profil</a>
                                        </div>
                                    </article>
                                )
                            })
                        }

                    </div>
                </div>
            </div>

        </div>
    );
}

export default PageNosEtudiants;
