import { Card } from 'react-bootstrap';
import '../style/user.css'
import { VscCircleLargeFilled, VscAdd, VscEdit } from "react-icons/vsc";
import { FaCamera } from "react-icons/fa";
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ModalExperience from '../composant/modals/ModalExperience';
import ModalFormation from '../composant/modals/ModalFormation';
import ModalProjet from '../composant/modals/ModalProjet';
import ModalArticle from '../composant/modals/ModalArticle';
import ModalProfilPhoto from '../composant/modals/ModalProfilPhoto';
import ModalChallenge from '../composant/modals/ModalChallenge';
import ModalJobs from '../composant/modals/ModalJobs';
import { useNavigate } from 'react-router-dom';
import { FaUserGroup } from 'react-icons/fa6';
import logo from '../assets/logo.png'

const User = () => {

    const navigate = useNavigate()

    const contenuDescription = useRef(null)

    const [compte, setCompte] = useState([{ nom: ' ', email: "", phone: '', filiere: "", promotion: "", niveau: "", diplome: "", societe: "", experience: "", mdp: "" }])
    const [expProfessionnel, setExpProfessionnel] = useState([{ societe: " ", poste: "", contrat: "", ville: "", debut: "", fin: "", competence: "" }]);
    const [formations, setFormations] = useState([{ ecole: " ", filiere: "", ville: "", debut: "", fin: "" }]);
    const [projets, setProjets] = useState([{ nom: " ", detail: "", competence: "", debut: "", fin: "" }]);
    const [challenges, setChallenges] = useState([{ domaine: "", sujet: "" }]);
    const [activites, setActivites] = useState([{ titre: "", sousTitre: "" }]);
    const [jobs, setJobs] = useState([{ type: '', profil: "" }]);
    const [editExperience, setEditExperience] = useState(false);
    const [editFormation, setEditFormation] = useState(false);
    const [article, setArticle] = useState(false);
    const [jobModal, setJobModal] = useState(false);
    const [editProjets, setEditProjets] = useState(false);
    const [editPhotoProfil, setEditPhotoProfil] = useState(false);
    const [modifierDescription, setModifierDescription] = useState(false);
    const [editChallenge, setEditChallenge] = useState(false);

    useEffect(() => {

        const resultFecth = async () => {

            try {
                const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/compte/alumni/${localStorage.getItem('admin')}`)
                setCompte(response.data['info'])

                setExpProfessionnel(response.data['info'].expProfessionnel)
                setFormations(response.data['info'].formations)
                setProjets(response.data['info'].projets)
                setChallenges(response.data['info'].challenges)
                setActivites(response.data['info'].activites)
                setJobs(response.data['info'].offreJobs)
            } catch (error) {
                console.log(error)
            }
        }
        resultFecth()

    }, []);


    const boutonValiderDescription = async () => {
        setModifierDescription(false)

        if (contenuDescription.current) {

            const field = {
                id: compte.id,
                description: contenuDescription.current.value
            }

            try {
                await axios.put('https://labtech-bakoffice.ufrssmt.edu.ci/api/compte/new_description/', field)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleChangePage = (path, id, index, article) => {
        navigate(path, { state: { id: id, index: index, article: article } })
        window.scroll(0, 0)
    }

    return (
        <div className="User">
            {localStorage.getItem("admin") ?
                <div>
<section className="section about-section gray-bg" id="about">
                <div className="containe" style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="about-text go-to">
                                {/* <h3 className="dark-color">Description</h3> */}
                                <h6 className="dark-color lead">{compte.nom}</h6>
                                <p>
                                    <div style={{ display: modifierDescription === false ? "initial" : "none" }}>
                                        {
                                            compte.description !== "" && (
                                                <span>
                                                    {compte.description}
                                                </span>
                                            )
                                        }
                                        <span onClick={() => setModifierDescription(true)}><VscEdit style={{ fontSize: "22px", cursor: 'pointer', color: "blue", marginLeft: "10px" }} /></span>
                                    </div>
                                    <div style={{ display: modifierDescription === true ? "initial" : "none" }}>
                                        <textarea ref={contenuDescription} style={{ fontSize: "15px", border: "1px solid rgba(0,0,0,0.2)", borderRadius: "5px" }} name="" id="" cols="40" rows="4"></textarea>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ backgroundColor: "red", color: "white", padding: "5px", fontWeight: 100, width: "100px", borderRadius: "5px", textAlign: "center", cursor: "pointer", marginRight: "10px" }} onClick={() => setModifierDescription(false)}>Annuler</div>
                                            <div style={{ backgroundColor: "#0e69b0", color: "white", padding: "5px", fontWeight: 100, width: "100px", borderRadius: "5px", textAlign: "center", cursor: "pointer" }} onClick={() => boutonValiderDescription()}>Valider</div>
                                        </div>
                                    </div>

                                </p>
                                <div className=" about-list" style={{ display: "flex", justifyContent: "space-around" }}>
                                    <div className="col-md-6">
                                        <div className="media">
                                            <label>Promotion </label>
                                            <p>{compte.promotion}</p>
                                        </div>
                                        <div className="media">
                                            <label>Niveau</label>
                                            <p>{compte.niveau}</p>
                                        </div>
                                        <div className="media">
                                            <label>E-mail</label>
                                            <p>{compte.email}</p>
                                        </div>
                                        <div className="media">
                                            <label>linkedIn</label>
                                            <p>{compte.linkedin}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="media">
                                            <label>Filière</label>
                                            <p>{compte.filiere}</p>
                                        </div>
                                        <div className="media">
                                            <label>Diplôme</label>
                                            <p>{compte.diplome}</p>
                                        </div>
                                        <div className="media">
                                            <label>Téléphone</label>
                                            <p>{compte.phone}</p>
                                        </div>
                                        <div className="media">
                                            <label>Domaine</label>
                                            <p>{compte.societe}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-avatar" style={{ position: "relative"}}>

                                <img src={`https://labtech-bakoffice.ufrssmt.edu.ci/profil_images/${compte.photo}`} alt={compte.photo} />
                                <br />
                                <span style={{ position: "absolute", bottom: "0", right: "0", color: "white", cursor: "pointer", fontSize: "18px", fontWeight: "200", backgroundColor: "blue", padding: "5px", paddingRight: "0", borderTopLeftRadius: "10px" }} onClick={() => setEditPhotoProfil(true)}><FaCamera /></span>
                            </div>
                        </div>
                    </div>
                    <div className="counter">
                        <div className="row">
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to="500" data-speed="500">{compte.experience}</h6>
                                    <p className="m-0px font-w-600">Expérience</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to="150" data-speed="150"> {compte.projets ? compte.projets.length : 0} </h6>
                                    <p className="m-0px font-w-600">Projets</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to="850" data-speed="850">{compte.challenges ? compte.challenges.length : 0}</h6>
                                    <p className="m-0px font-w-600">Challenges</p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="count-data text-center">
                                    <h6 className="count h2" data-to="190" data-speed="190">{activites ? activites.length : 0}</h6>
                                    <p className="m-0px font-w-600">Articles</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='conteneurExperienceFormation'>

                        <div className='experienceProfessionnels' style={{ marginRight: "10px" }}>
                            <Card>
                                <Card.Header>
                                    <div className='headerDisposition'>
                                        <div>Expérience professionnel</div>
                                        <div className='headerDispositionDeux'>
                                            <span onClick={() => setEditExperience(true)}> <VscAdd /> </span>
                                            <span><VscEdit /></span>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {
                                            expProfessionnel.map((data, index) => {
                                                return (
                                                    <div className='elementExperience' key={index}>
                                                        <div className='pointRepere'><VscCircleLargeFilled /></div>
                                                        <div className='elementExperiencePoste'>{data.poste}</div>
                                                        <div className='elementExperienceEntreprise'>
                                                            <span className='nomEntreprise'>{data.societe}</span>
                                                            <VscCircleLargeFilled className='pointEntreprise' />
                                                            <span className='nomEntreprise'>{data.contrat}</span>
                                                        </div>
                                                        <div className='elementExperienceDuree'>
                                                            <span>{data.debut}</span>
                                                            <span> - </span>
                                                            <span>{data.fin}</span>
                                                        </div>
                                                        <div className='elementExperienceLieu'>
                                                            {data.ville}
                                                        </div>

                                                        <div className='elementExperienceCompetence'>
                                                            <header>Compétences</header>
                                                            <div className='descriptionCompetence'>
                                                                {data.competence}
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className='experienceProfessionnels' style={{ marginRight: "10px" }}>
                            <Card>
                                <Card.Header>
                                    <div className='headerDisposition'>
                                        <div>Formation</div>
                                        <div className='headerDispositionDeux'>
                                            <span onClick={() => setEditFormation(true)}> <VscAdd /> </span>
                                            <span><VscEdit /></span>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {
                                            formations.map((data, index) => {
                                                return (
                                                    <div className='elementExperience' key={index}>
                                                        <div className='pointRepere'><VscCircleLargeFilled /></div>
                                                        <div className='elementExperiencePoste'>{data.ecole}</div>

                                                        <div className='elementExperienceEntreprise'>
                                                            <span className='nomEntreprise'>{data.filiere}</span>
                                                        </div>

                                                        <div className='elementExperienceDuree'>
                                                            <span>{data.debut}</span>
                                                            <span> - </span>
                                                            <span>{data.fin}</span>
                                                        </div>
                                                        <div className='elementExperienceLieu'>
                                                            {data.ville}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>


                    {/* informations et Activités sur la plateforme */}
                    <br />
                    <div className='conteneurExperienceFormation'>

                        <div className='experienceProfessionnels' style={{ marginRight: "10px" }}>
                            <Card>
                                <Card.Header>
                                    <div className='headerDisposition'>
                                        <div>Projets</div>
                                        <div className='headerDispositionDeux'>
                                            <span onClick={() => setEditProjets(true)}> <VscAdd /> </span>
                                            <span><VscEdit /></span>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {
                                            projets.map((data, index) => {
                                                return (
                                                    <div className='elementExperience' key={index}>
                                                        <div className='pointRepere'><VscCircleLargeFilled /></div>
                                                        <div className='elementExperiencePoste'>{data.nom}</div>
                                                        <div className='elementExperienceEntreprise'>
                                                            <span className='nomEntreprise'>Entreprise</span>
                                                            <VscCircleLargeFilled className='pointEntreprise' />
                                                            <span className='nomEntreprise'>Type contrat</span>
                                                        </div>
                                                        <div className='elementExperienceDuree'>
                                                            <span>{data.debut}</span>
                                                            <span> - </span>
                                                            <span>{data.fin}</span>
                                                        </div>

                                                        <div className='elementExperienceCompetence'>
                                                            <header>Détail</header>
                                                            <div className='descriptionCompetence'>
                                                                {data.detail}
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='experienceProfessionnels' style={{ marginRight: "10px" }}>
                            <Card>
                                <Card.Header>
                                    <div className='headerDisposition'>
                                        <div>Challenges</div>
                                        <div className='headerDispositionDeux'>
                                            <span onClick={() => setEditChallenge(true)}> <VscAdd /> </span>
                                            <span><VscEdit /></span>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {
                                            challenges.map((data) => (
                                                <div className='elementExperience'>
                                                    <div className='pointRepere'><VscCircleLargeFilled /></div>
                                                    <div className='elementExperiencePoste'>{data.domaine}</div>

                                                    <div className='elementExperienceCompetence'>
                                                        <header>Sujet</header>
                                                        <div className='descriptionCompetence'>
                                                            {data.sujet.substring(0, 190)}...
                                                        </div>

                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}><FaUserGroup style={{ fontSize: "22px", color: "#191970" }} /> <span> {data.reponse ? `${data.reponse.length} réponses` : 0} </span></div>
                                                    <a href={`/admin/repondre-au-challenge-info/${data.domaine}/${data._id}`} style={{ marginLeft: "20px" }}>Voir les réponses</a>
                                                </div>
                                            ))
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                    </div>

                    {/* INFORMATIONS */}
                    <br /><br />

                    <div className='conteneurExperienceFormation'>
                        <div className='experienceProfessionnels'>
                            <Card>
                                <Card.Header>
                                    <div className='headerDisposition'>
                                        <div>Activités</div>
                                        <div className='headerDispositionDeux'>
                                            <span onClick={() => setArticle(true)}> <VscAdd /> </span>
                                            <span><VscEdit /></span>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {
                                            activites.map((data, index) => (
                                                <div className='elementExperience' key={index}>
                                                    <div className='pointRepere'><VscCircleLargeFilled /></div>
                                                    <div className='elementExperiencePoste'>{data.titre}</div>
                                                    <div className='elementExperienceLieu'>
                                                        {data.sousTitre.substring(0, 200)}...
                                                        <a style={{ color: "blue", cursor: "pointer" }} onClick={() => handleChangePage(`/actualité/${data.titre}`, 0, index, data._id)}>En savoir plus</a>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className='experienceProfessionnels' style={{ marginLeft: "10px" }}>
                            <Card>
                                <Card.Header>
                                    <div className='headerDisposition'>
                                        <div>Offre : Emploi - stage</div>
                                        <div className='headerDispositionDeux'>
                                            <span onClick={() => setJobModal(true)}> <VscAdd /> </span>
                                            <span><VscEdit /></span>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {
                                            jobs.map((data, index) => (
                                                <div className='elementExperience' key={{ index }}>
                                                    <div className='pointRepere'><VscCircleLargeFilled /></div>
                                                    <div className='elementExperiencePoste'>{data.type}</div>
                                                    <div className='elementExperienceLieu'>
                                                        <span>Domaine : </span> <span>{data.domaine}</span> <br />
                                                        <span>Profil : </span> <span style={{ color: "black" }}>{data.profil.substring(0, 100)}...</span> <br />
                                                        <span>Date Limite : </span><span style={{ color: "black" }}>{data.dateLimite}</span>
                                                        <br />
                                                        <a style={{ backgroundColor: 'red', padding: "10px", borderRadius: "10px", color: "white", cursor: "pointer" }} href={`/Accueil/job-a-pouvoir/${data.type}/${data._id}`}>Détail</a>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>


                </div>
            </section >

            {/* les boites de dialogue */}
            <ModalExperience
                show={editExperience}
                id={compte.id}
                onHide={() => setEditExperience(false)}
            />
            <ModalFormation
                show={editFormation}
                id={compte.id}
                onHide={() => setEditFormation(false)}
            />

            <ModalProjet
                show={editProjets}
                onHide={() => setEditProjets(false)}
                id={compte.id}
            />

            <ModalArticle
                show={article}
                onHide={() => setArticle(false)}
                id={compte.id}
            />

            <ModalChallenge
                show={editChallenge}
                onHide={() => setEditChallenge(false)}
                id={compte.id}
            />

            <ModalProfilPhoto
                show={editPhotoProfil}
                onHide={() => setEditPhotoProfil(false)}
                id={compte.id}
            />

            <ModalJobs
                show={jobModal}
                onHide={() => setJobModal(false)}
                id={compte.id}
            />
                </div>


                :

                <div style={{height:"100vh", display:"flex", alignItems: "center", justifyContent: "center", backgroundColor:"rgba(64, 162, 216, 0.2)"}}>

                    <div style={{margin:"10px", backgroundColor:"rgb(76, 158, 231)", padding:"10px", fontWeight:"100", color:"white", borderRadius:"10px", width:"120px",cursor:"pointer", textAlign:"center"}} onClick={() =>navigate('/connexion')}>
                        Se connecter
                    </div>

                    <div style={{margin:"10px", backgroundColor:"rgb(76, 158, 231)", padding:"10px", fontWeight:"100", color:"white", borderRadius:"10px", width:"120px",cursor:"pointer", textAlign:"center"}} onClick={() =>navigate('/inscription')}>
                        S'inscrire
                    </div>
                                    
                </div>
                
                }

        </div >
    )
}

export default User;