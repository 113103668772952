import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

//importation des css
import '../../style/modal/modalExperience.css'
import axios from "axios";

const ModalFormation = ({ show, onHide, id }) => {

    const {register, handleSubmit, reset} = useForm()

    const newFormation = (data) => {

        const field = {
            id: id,
            ecole: data.ecole,
            filiere: data.filiere,
            ville: data.ville,
            debut: data.debut,
            fin: data.fin         
        }

        addExperience(field)

        reset()
    }

    const addExperience = async (data) => {
        
        try {

            await axios.put('https://labtech-bakoffice.ufrssmt.edu.ci/api/formation/new_formation/', data)
            
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nouvelle formation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(newFormation)}>
                            <div className="inputZone">
                                <label htmlFor="">Ecole</label>
                                <input type="text" placeholder="Université Felix Houphouet Boigny" {...register('ecole')}/>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Filière</label>
                                <input type="text" placeholder="Engénieur électromécanique" {...register('filiere')}/>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Ville</label>
                                <input type="text" placeholder="Abidjan" {...register('ville')}/>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Date de début</label>
                                <input type="date" {...register('debut')} />
                            </div>
                            <div className="inputZone">
                                <label htmlFor="">Date de fin</label>
                                <input type="date" {...register('fin')}/>
                            </div>

                            <div className="inputZoneSubmit">
                                <label htmlFor="envoyer">Ajouter</label>
                                <input type="submit" id="envoyer" style={{display:'none'}}/>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ModalFormation;