import { Modal, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";

//importation des css
import '../../style/modal/modalExperience.css'
import axios from "axios";
import { IoMdCamera } from "react-icons/io";
import { useState } from "react";

const ModalArticle = ({ show, onHide, id }) => {

    const [filePreview, setFilePreview] = useState(false);
    const [fileDeuxPreview, setFileDeuxPreview] = useState(false);
    const [fileImages, setFileImages] = useState(false);
    const [fileDeuxImages, setFileDeuxImages] = useState(false);
    const { register, handleSubmit, reset } = useForm()
    const [toast, setToast] = useState(false);

    const newArticle = (data) => {

        addArticle(data)
        setToast(true)
        reset()
        setFilePreview(false)
        setFileImages(false)
        setFileDeuxImages(false)
        setFileDeuxImages(false)
        setFileDeuxPreview(false)

    }

    const addArticle = async (data) => {

        let formData = new FormData()

        const tableImage = []

        tableImage.push(fileImages)

        formData.append('titre', data.titre)
        formData.append('sousTitre', data.sousTitre)
        formData.append('categorie', data.categorie)
        formData.append('alaune', data.alaUne)
        formData.append('description', data.description)
        formData.append('titreDeux', data.titreDeux)
        formData.append('sousTitreDeux', data.sousTitreDeux)
        formData.append('descriptionDeux', data.descriptionDeux)

        if (fileDeuxImages) {
            tableImage.push(fileDeuxImages)
        }

        for (let i = 0; i < tableImage.length; i++) {
            formData.append(`file`, tableImage[i])
        }

        formData.append('id', id)

        try {

            await axios.post('https://labtech-bakoffice.ufrssmt.edu.ci/api/article/new_article/', formData,{headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}}            )

        } catch (error) {
            console.log(error)
        }

        // console.log(response)
        reset()
    }



    // Cette fonction est déclenchée lorsqu'un fichier est sélectionné
    const handleFileChange = (event) => {
        // console.log(event)
        const selectedFile = event.target.files[0];
        setFileImages(selectedFile)

        // Vérification que le fichier est une image
        if (selectedFile && selectedFile.type.startsWith('image')) {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);

            // Lecture du contenu du fichier
            reader.onloadend = () => {
                // Mise à jour du state avec l'URL du fichier
                setFilePreview(reader.result);
            };
        } else {
            setFilePreview(false);
        }
    };


    const handleFileDeuxChange = (event) => {
        // console.log(event)
        const selectedFile = event.target.files[0];
        setFileDeuxImages(selectedFile)

        // Vérification que le fichier est une image
        if (selectedFile && selectedFile.type.startsWith('image')) {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);

            // Lecture du contenu du fichier
            reader.onloadend = () => {
                // Mise à jour du state avec l'URL du fichier
                setFileDeuxPreview(reader.result);
            };
        } else {
            setFileDeuxPreview(false);
        }
    };


    const closeModal = () => {
        reset()
        onHide(false)
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Ajouter article
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(newArticle)}>
                            <div className="inputZone">
                                <label htmlFor="">Titre</label>
                                <input type="text" placeholder="Présentation des filières" {...register('titre')} required />
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Sous titre</label>
                                <textarea id=""  {...register('sousTitre')} cols="30" rows="5"></textarea>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Catégorie</label>
                                <select {...register('categorie')}>
                                    <option>Actualités</option>
                                    <option>fêtes</option>
                                    <option>évènements</option>
                                </select>
                            </div>

                            <div style={{ fontSize: "12px" }}>
                                <label htmlFor="">A La une</label>
                                <input type="checkbox" style={{ transform: "scale(1.2)", margin: "10px" }} {...register('alaUne')} />
                            </div>

                            <div className="inputZone">
                                <textarea id="" cols="30" rows="10" {...register('description')} placeholder="Description..."></textarea>
                            </div>


                            <label htmlFor="imageArticle" style={{ fontSize: "22px", margin: "10px", backgroundColor: "rgb(76, 158, 231)", color: "white", padding: "5px", display: "flex", alignItems: "center", width: "190px", textAlign: "center", borderRadius: "4px", cursor: "pointer" }}>
                                <IoMdCamera /> <span>Joindre média</span>
                            </label>
                            <input type="file" id="imageArticle" accept="image/*" style={{ display: "none" }} onChange={(event) => handleFileChange(event)} />

                            <div className="voirImageimporter">
                                {
                                    filePreview && (
                                        <div>
                                            {/* Affichage de l'aperçu de l'image */}
                                            <img src={filePreview} alt="File Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                        </div>
                                    )
                                }
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Titre 2</label>
                                <input type="text" placeholder="Présentation des filières" {...register('titreDeux')} />
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Sous titre 2</label>
                                <textarea id=""  {...register('sousTitreDeux')} cols="30" rows="5"></textarea>
                            </div>

                            <div className="inputZone">
                                <textarea id="" cols="30" rows="5" {...register('descriptionDeux')} placeholder="Description..."></textarea>
                            </div>

                            <label htmlFor="imageArticleDeux" style={{ fontSize: "22px", margin: "10px", backgroundColor: "rgb(76, 158, 231)", color: "white", padding: "5px", display: "flex", alignItems: "center", width: "190px", textAlign: "center", borderRadius: "4px", cursor: "pointer" }}>
                                <IoMdCamera /> <span>Joindre média</span>
                            </label>
                            <input type="file" id="imageArticleDeux" accept="image/*" style={{ display: "none" }} onChange={(event) => handleFileDeuxChange(event)} />

                            <div className="voirImageimporter">
                                {
                                    fileDeuxPreview && (
                                        <div>
                                            {/* Affichage de l'aperçu de l'image */}
                                            <img src={fileDeuxPreview} alt="File Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                        </div>
                                    )
                                }
                            </div>

                            <br />
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="inputZoneSubmit" onClick={() => closeModal()}>
                                    <label htmlFor="" style={{ backgroundColor: "red", marginRight: "20px", width: "160px" }}>Annuler</label>
                                </div>
                                <div className="inputZoneSubmit">
                                    <label htmlFor="envoyer" style={{ width: "160px" }}>Publier</label>
                                    <input type="submit" id="envoyer" style={{ display: 'none' }} />
                                </div>
                            </div>
                            <div>
                                <Toast style={{ width: '100%' }} onClose={() => setToast(false)} show={toast} delay={10000} autohide>
                                    <Toast.Body style={{ backgroundColor: 'seagreen', padding: "10px", textAlign: "center", color: "white" }}>
                                        Votre poste a été ajouté avec succès
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalArticle;