import { FaClock, FaMailBulk } from 'react-icons/fa';
import '../style/lireActualite.css'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Toast } from 'react-bootstrap';
import Footer from '../composant/Footer';
import RecentActualite from '../composant/RecentActualite';
import Tags from '../composant/Tags';


const PageLireArticle = () => {

    const [article, setArticle] = useState({ file: "", fileDeux: "", categorie: "", titre: '', titreDeux: "", sousTitre: "", sousTitreDeux: "", description: "", descriptionDeux: "" });


    const [toastCommentaire, setToastCommentaire] = useState(false);

    const { state } = useLocation()

    const navigate = useNavigate()
    const { register, handleSubmit, reset } = useForm()

    useEffect(() => {

        const recu_article = async () => {

            const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/lire_article/${state.article}`)

            setArticle(response.data['info'][0])
            console.log(response.data['info'])

        }

        recu_article()

    }, [state])

    const handleCommentaire = (data) => {

        setToastCommentaire(true)

        const field = {
            id: article._id,
            nom: data.nom,
            email: data.email,
            message: data.message
        }

        execuCommentaire(field)

        reset()

    }

    const execuCommentaire = async (data) => {
        const response = await axios.post('https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/commentaire_article', data,{headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}})
        console.log(response)
    }

    return (
        <div className="lireActualite">

            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span onClick={() => navigate('/dashboard')} style={{ cursor: "pointer" }}>Dashboard</span> <MdKeyboardDoubleArrowRight /> <span style={{ cursor: "pointer" }}>{article.categorie}</span>
            </header>

            <section class="single-block-wrapper section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div class="single-post">
                                <div class="post-header mb-5">
                                    <a class="post-category">{article.categorie}</a>
                                    <h3 class="post-title" style={{fontSize:"25px", lineHeight:"33px"}}>
                                        {article.titre}
                                    </h3>
                                    <p>{article.sousTitre}</p>
                                </div>
                                <div class="post-body">
                                    <div class="post-featured-image">
                                        <img src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${article.file}`} class="img-fluid" alt="featured-image" />
                                    </div>
                                    <div class="entry-content">
                                        <p>{article.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}</p>
                                        {/* <p>
                                            {article.description}
                                        </p> */}
                                        {
                                            article.titreDeux && (
                                                <h2>{article.titreDeux}</h2>
                                            )
                                        }

                                        {
                                            article.sousTitreDeux && (
                                                <p>
                                                    {article.sousTitreDeux}
                                                </p>
                                            )
                                        }


                                        <div class="media mb-4 single-media">
                                            {
                                                article.fileDeux && (
                                                    <img src={`https://labtech-bakoffice.ufrssmt.edu.ci/profil_images/${article.fileDeux}`} alt="post-ads" class="img-fluid mr-4" />
                                                )
                                            }
                                            {
                                                article.descriptionDeux && (
                                                    <div class="media-body">
                                                        <p>
                                                            {article.descriptionDeux}
                                                        </p>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>

                                    <div class="share-block  d-flex justify-content-between align-items-center border-top border-bottom mt-5">
                                        <Tags />
                                    </div>
                                </div>
                            </div>

                            <div id="comments" class="comments-block block">
                                <h3 class="news-title">
                                    <span>Commentaires</span>
                                </h3>
                                <ul class="all-comments">
                                    {
                                        article.commentaires && (
                                            article.commentaires.map((data, index) => (
                                                <li key={index}>
                                                    <div class="comment">
                                                        <div class="comment-body">
                                                            <div class="meta-data">
                                                                <span class="commented-person-name">{data.nom}</span>
                                                                <span class="comment-hour d-block" style={{ marginLeft: "30px" }}><FaMailBulk style={{ marginRight: "10px" }} /><a href={`mailto:${data.email}`}>{data.email}</a></span>
                                                                <span class="comment-hour d-block" style={{ marginLeft: "30px" }}><FaClock style={{ marginRight: "10px" }} />{data.temps}</span>
                                                            </div>
                                                            <div class="comment-content" style={{ marginLeft: "30px" }}>
                                                                <p>
                                                                    {data.message}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        )
                                    }

                                </ul>
                            </div>

                            <div class="comment-form ">
                                <h3 class="title-normal">Laisser un commentaire </h3>
                                <form onSubmit={handleSubmit(handleCommentaire)}>
                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input class="form-control" name="name" id="name" placeholder="Name" type="text" required {...register('nom')} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input class="form-control" name="email" id="email" placeholder="Email" type="email" required {...register('email')} />
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <textarea class="form-control required-field" id="message" placeholder="Message" rows="8" required {...register('message')}></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <button class="comments-btn btn btn-primary " type="submit">Poster un commentaire</button>
                                        </div>

                                    </div>
                                </form>

                            </div>
                                {/* commmentaire */}
                            <Toast class="col-md-12" style={{ width: '100%' }} onClose={() => setToastCommentaire(false)} show={toastCommentaire} delay={10000} autohide>
                                <Toast.Body style={{ backgroundColor: 'seagreen', padding: "10px", textAlign: "center", color: "white" }}>
                                    Commentaire envoyé avec succès
                                </Toast.Body>
                            </Toast>
                        </div>
                        
                        {/* recent article */}
                        <RecentActualite />
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default PageLireArticle;