import { Button, Card, Col, Container, Row } from "react-bootstrap";
import '../style/actualite.css'
import img_actualite from '../assets/NftBanner1.png'
import Footer from "./Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaUserGroup } from "react-icons/fa6";

const Challenge = () => {

    const navigate = useNavigate()

    const [dataChallenge, setDataChallenge] = useState([{ domaine: "", sujet: "" }]);

    useEffect(() => {
        const recu = async () => {

            try {

                const response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/challenge/afficher_challenge/')
                setDataChallenge(response.data['info'])

            } catch (error) {
                console.log(error)
            }

        }
        recu()

    }, [])


    const handlePage = (path, id) => {
        navigate(path, { state: { id } })
        window.scroll(0, 0)
    }

    return (
        <div className="Actualite" style={{ paddingTop: "85px", paddingLeft: "10px" }}>
            <header>
                <Card className="bg-dark text-white" style={{ borderRadius: "20px" }}>
                    <Card.Img src={img_actualite} alt="Card image" style={{ height: "35vh", borderRadius: "20px" }} />
                    <Card.ImgOverlay>
                        <Card.Title style={{ fontFamily: "cursive", fontSize: "18px", margin: "10px" }}>Challenge du jour</Card.Title>
                        <Card.Text style={{ margin: "10px", color: "white" }}>
                            {dataChallenge[0].sujet.substring(0, 150)}...
                        </Card.Text>
                        <Button style={{ marginLeft: "50px", marginTop: "10px" }} onClick={() => handlePage(`/Challenge/repondre-au-challenge/${dataChallenge[0].domaine}`, dataChallenge[0]._id)}>
                            Repondre
                        </Button>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "right", position: "absolute", bottom: "0", right: "20px" }}>
                            <FaUserGroup style={{ fontSize: "22px" }} /> <span> {dataChallenge[0].reponse ? `${dataChallenge[0].reponse.length} réponses` : 0} </span>
                        </div>
                    </Card.ImgOverlay>

                </Card>
            </header>
            <br />

            <header className="headerElementChallenge">Autre Challenge</header>

            <div className="vertical-tabs Dashboard">
                <div className="content ">

                    <div className="content-main">
                        <div className="card-grid">

                            {
                                dataChallenge.map((data, index) => {
                                    return (
                                        <article className="card" key={index}>
                                            <div className="card-header">
                                                <div>
                                                    <h6>{data.domaine}</h6>
                                                </div>
                                                <label className="toggle" style={{ cursor: "pointer", fontSize: "12px", backgroundColor: "#191970", padding: "8px", color: "white", borderRadius: "9px" }} onClick={() => handlePage(`/Challenge/repondre-au-challenge/${data.domaine}`, data._id)}>
                                                    Répondre
                                                </label>
                                            </div>
                                            <div className="card-body">
                                                <p>{data.sujet.substring(0, 150)}....</p>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}><FaUserGroup style={{ fontSize: "22px", color: "#191970" }} /> <span> {data.reponse ? `${data.reponse.length} réponses` : 0} </span></div>
                                        </article>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Challenge;