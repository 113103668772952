import '../style/lireActualite.css'
import Footer from './Footer'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import logo from '../assets/logo img.png'
import ima_1 from '../assets/img_labtech/12.jpg'
import ima_6 from '../assets/img_labtech/6.jpg'
import ima_7 from '../assets/img_labtech/7.jpg'
import ima_16 from '../assets/img_labtech/16.jpg'
import ima_17 from '../assets/img_labtech/17.jpg'
import ima_18 from '../assets/img_labtech/18.jpg'
import ima_19 from '../assets/img_labtech/19.jpg'
import photo_alumni_1 from '../assets/img_labtech/alumni/1.jpeg'
import photo_alumni_2 from '../assets/img_labtech/alumni/2.jpg'
import photo_alumni_3 from '../assets/img_labtech/alumni/3.jpg'
import directeur_img from '../assets/img_labtech/presentation-5.jpg'
import { useNavigate } from 'react-router-dom';
import Tags from './Tags';


const PresentationLabTech = () => {

    const navigate = useNavigate()

    return (
        <div className="lireActualite">

            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span onClick={() => navigate('/accueil')} style={{ cursor: "pointer" }}>Accueil</span> <MdKeyboardDoubleArrowRight /> <span style={{ cursor: "pointer" }}>Actualités</span> <MdKeyboardDoubleArrowRight /> <span>Laboratoire de technologie</span>
            </header>

            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up">

                    <div className="row">

                        <div className="col-lg-8 entries">

                            <article className="entry entry-single">

                                <div className="entry-img">
                                    <img src={ima_1} alt="" className="img-fluid" />
                                </div>

                                <h2 className="entry-title" style={{ fontSize: "22px" }}>
                                    <span >Laboratoire de technologie de l'université Félix Houphouet Boigny de Cocody</span>
                                </h2>

                                {/* <div className="entry-meta">
                                    <ul>
                                        <li className="d-flex align-items-center"><FaUserCircle /> <a>John Doe</a></li>
                                        <li className="d-flex align-items-center"><FaClock /><a ><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
                                        <li className="d-flex align-items-center"><FaComment /> <a>12 Comments</a></li>
                                    </ul>
                                </div> */}

                                <div className="entry-content" style={{ textAlign: 'justify' }}>
                                    <p>
                                        Le Laboratoire de Technologie de l'Université Félix Houphouet-Boigny de Cocody est un centre d'excellence dédié à la recherche et au développement dans le domaine de la technologie. Fortement engagé dans l'avancement des connaissances et des compétences, le laboratoire joue un rôle essentiel dans la formation des étudiants, la promotion de la recherche innovante et la collaboration avec l'industrie.
                                    </p>

                                    <p>Notre laboratoire se distingue par sa vision axée sur l'exploration des nouvelles frontières technologiques et son engagement envers l'excellence académique. En tant qu'institution de renom, nous mettons l'accent sur la création d'un environnement propice à la découverte, à l'innovation et à la résolution de problèmes complexes.</p>

                                    <p>Les domaines de recherche couverts par le laboratoire englobent une gamme diversifiée de disciplines technologiques, allant de l'informatique et de l'ingénierie électrique à la biotechnologie et aux sciences des matériaux. Cette diversité reflète notre engagement envers une approche holistique de la technologie, favorisant ainsi un échange interdisciplinaire de connaissances.</p>

                                    <p>En collaboration avec des partenaires académiques et industriels, le Laboratoire de Technologie de l'Université Félix Houphouet-Boigny de Cocody aspire à repousser les limites de l'innovation technologique, à contribuer à des avancées significatives et à préparer la prochaine génération de leaders technologiques.</p>

                                    <p>Notre engagement envers l'excellence, la recherche de pointe et l'application pratique des connaissances fait du Laboratoire de Technologie un acteur clé dans le paysage académique et industriel, contribuant ainsi au progrès technologique et au développement socio-économique.</p>

                                    
                                    <h3>Mot du Directeur de Laboratoire de Technologie</h3>
                                    <img src={directeur_img} className="img-fluid" alt="" />
                                    <br />
                                    <p>
                                        C'est avec un grand plaisir que je m'adresse à vous en tant que directeur du laboratoire de technologie. Notre laboratoire est un lieu d'innovation, de collaboration et d'excellence, et je suis honoré de travailler avec une équipe aussi talentueuse et dévouée.
                                    </p>
                                    <p>
                                        Au fil des années, nous avons accompli des avancées remarquables dans le domaine de la technologie, contribuant ainsi à façonner l'avenir de notre discipline. Notre engagement envers la recherche de pointe, le développement de technologies novatrices et la formation de la prochaine génération de leaders est au cœur de notre mission.
                                    </p>
                                    <p>

                                        <p>
                                            Je tiens à exprimer ma gratitude envers chacun d'entre vous pour votre dévouement, votre expertise et votre passion qui ont été les moteurs de nos succès. Ensemble, nous avons surmonté des défis, repoussé les limites et créé un environnement propice à l'excellence scientifique.
                                        </p>

                                        <p>
                                            Alors que nous regardons vers l'avenir, je suis convaincu que notre laboratoire continuera à jouer un rôle essentiel dans la résolution des défis technologiques complexes qui se présentent à nous. Nous devons rester audacieux, créatifs et collaboratifs pour relever les défis émergents et maintenir notre position en tant que pionniers de la technologie.
                                        </p>
                                        <p>
                                            Je vous encourage à poursuivre votre travail acharné, à partager vos idées et à collaborer avec vos collègues. Ensemble, nous pouvons continuer à repousser les frontières de la connaissance technologique et à inspirer l'innovation.
                                        </p>
                                        <p>
                                            Merci encore pour votre engagement continu et votre contribution exceptionnelle. Je suis impatient de voir les réalisations extraordinaires que nous accomplirons ensemble dans les années à venir.
                                        </p>
                                        <p>
                                            Cordialement,
                                        </p>
                                        <p>
                                            <strong>Pr Graffouté Moussa</strong> <br />
                                            Directeur du Laboratoire de Technologie
                                        </p>
                                    </p>

                                    <h3>Parcours initial professionnel</h3>
                                    <p>
                                        <ul>
                                            <li>
                                                <h4>EEAI : Electronique Electrotechnique Automatique & Informatique</h4>
                                                <ul>
                                                    <li><h5>Conditions d'accès</h5>
                                                        <ul>
                                                            <li>Bac+1 de Physique chimie à l'UFR SSMT</li>
                                                            <li>Licence 3 EEAI pour le cycle Master</li>
                                                        </ul>
                                                    </li>
                                                    <li><h5>Objectifs</h5>
                                                        <ul>
                                                            <li>Former des responsables et des cadres opérationnels en Electronique, Electrotechnique, Automatique et Informatique, et leurs applications (Robotique, automobile, aéronautique, etc...).</li>
                                                        </ul>
                                                    </li>
                                                    <li><h5>Débouchés</h5>
                                                        <ul>
                                                            <li>Ingénieur de maintenance industrielle, Responsable des systèmes de production, Ingénieur en développement python.</li>
                                                        </ul>
                                                    </li>

                                                </ul>
                                            </li>
                                            <br /><br />
                                            <li><h4>EEM : Energétique Electro-Mécanique</h4>
                                                <ul>
                                                    <li><h5>Conditions d'accès</h5>
                                                        <ul>
                                                            <li>Bac+1 de physique chimie à l'UFR SSMT</li>
                                                            <li>Licence 3 EEM pour le cycle Master</li>
                                                        </ul></li>

                                                    <li><h5>Objectifs</h5>
                                                        <ul>
                                                            <li>Former des responsables et des cadres opérationnels en Energétique et Ingénierie Mécanique, et leurs applications (Mécanique, énergetique, Mécatronique, RDM, etc...)</li>
                                                        </ul>
                                                    </li>

                                                    <li><h5>Débouchés</h5>
                                                        <ul><li>Ingénieur mécanique, Ingénieur dtructure, Ingénieur Conception système mécatronique, Responsable bureau d'étude en efficacité énergétique, chef de projet</li></ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>

                                    <h3>Parours Professionnel</h3>
                                    <p>
                                        <ul>
                                            <li><h4>IME : Ingénierie Mécatronique & Energétique</h4>
                                                <ul>
                                                    <li><h5>Conditions d'accès</h5>
                                                        <ul>
                                                            <li>Bac scientifique</li>
                                                            <li>BTS</li>
                                                            <li>DUT</li>
                                                            <li>Licences</li>
                                                        </ul>
                                                    </li>
                                                    <li><h5>Objectifs & Débouchés</h5>
                                                        <ul>
                                                            <li>Former des spécialistes en Mécatronique et énergetique</li>
                                                            <li>Ingénieur Mécatronique, Technicien Energétique</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <br /><br />
                                            <li><h4>IRST : Ingénierie Réseaux & Systèmes de Télécommunication</h4>
                                                <ul>
                                                    <li><h5>Conditions d'accès</h5>
                                                        <ul>
                                                            <li>Bac scientifique</li>
                                                            <li>BTS</li>
                                                            <li>Licences</li>
                                                        </ul>
                                                    </li>
                                                    <li><h5>Objectifs & Débouchés</h5>
                                                        <ul>
                                                            <li>Former des spécialictes en Réseaux Télécom</li>
                                                            <li>Ingénieur Télécom, Technicien Réseaux</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>

                                    <h3>Master Spécialisé</h3>
                                    <p>
                                        <ul>
                                            <li><h4>EST : Electronique & Systèmes de Télécommunication</h4>
                                                <ul>
                                                    <li><h5>Conditions d'accès</h5>
                                                        <ul>
                                                            <li>Licence 3 Physique, </li>
                                                            <li>Physique, </li>
                                                            <li>EEAI, </li>
                                                            <li>EEM,</li>
                                                            <li>Licence équivalent</li>
                                                        </ul>
                                                    </li>

                                                    <li><h5>Objectifs</h5>
                                                        <ul>
                                                            <li>Former des responsable et des cadres opérationnels spécialictes en télécommunications (Transmission, Fibre option, Antennes, Réseaux Mobiles, etc...)</li>
                                                        </ul>
                                                    </li>

                                                    <li><h5>Débouchés</h5>
                                                        <ul>
                                                            <li>Ingénieur Télécom, Ingénieur Réseaux mobiles, responsable planification ,chef de projet TNT</li></ul></li>
                                                </ul>
                                            </li>

                                            <li><h4>PSA : Physique du Solide & Applications</h4>
                                                <ul>
                                                    <li><h5>Conditions d'accès</h5>
                                                        <ul>
                                                            <li>Licence 3 physique</li>
                                                            <li>EEAI, </li>
                                                            <li>EEM</li>
                                                            <li>Licence équivalentes</li></ul></li>

                                                    <li><h5>Objectifs</h5>
                                                        <ul><li>Former des responsables et des cadres opérationnels en physique du solide et ses Applications (Physique du semi-conducteur, Microéléctroniques, Physiques des matériaux).</li></ul>
                                                    </li>

                                                    <li><h5>Débouchés</h5>
                                                        <ul>
                                                            <li>
                                                                Ingénieur microélectronique, ingénieur nanosciences, ingénieur recherche et développement procédés, spécialiste process</li></ul></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>

                                    <h3>Nos ALUMNIS</h3>
                                    <p>
                                        Ils sont passés par nos filières techniques

                                        <br />

                                        <section class="section-padding" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <h4 class="mb-4">M. Anicet AMANI</h4>
                                                        <p>PDG SKAN TECHNOLOGIE</p>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <img src={photo_alumni_1} alt="" class="img-fluid rounded" />
                                                    </div>
                                                </div>

                                                <div class="row mt-5">
                                                    <div class="col-lg-6 col-md-6">
                                                        <img src={photo_alumni_2} alt="" class="img-fluid rounded" />
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <h4 class="mb-4">M. AKA Stephane</h4>
                                                        <p>Enseignant-Chercheur <br /> UNIVERSITE FELIX HOUPHOUET BOIGNY</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section class="section-padding" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <h4 class="mb-4">M. Franck Didier NENE</h4>
                                                        <p>Ingénieur Télé-conduite Télécom <br />
                                                            CI-ENERGIE</p>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <img src={photo_alumni_3} alt="" class="img-fluid rounded" />
                                                    </div>
                                                </div>

                                            </div>
                                        </section>
                                       
                                    </p>



                                    <h3>Nos salles de cours et de Travaux pratiques</h3>
                                    <ul class="img-list">
                                        <li><img src={ima_6} /></li>
                                        <li><img src={ima_7} /></li>
                                        <li><img src={ima_16} /></li>
                                    </ul>

                                    <ul class="img-list">
                                        <li><img src={ima_17} /></li>
                                        <li><img src={ima_19} /></li>
                                        <li><img src={ima_18} /></li>
                                    </ul>

                                </div>

                                <div className="entry-footer">
                                    <ul className="tags">
                                        <li><a>Images</a></li>
                                        <li><a>Vidéos</a></li>
                                        <li><a>Professeurs</a></li>
                                        <li><a>Filières</a></li>
                                    </ul>
                                </div>

                            </article>


                        </div>

                        <div className="col-lg-4">

                            <div className="sidebar">

                               

                                <h3 className="sidebar-title">Plateaux techniques</h3>
                                <div className="sidebar-item categories">
                                    <ul>
                                        <li><a><img src={logo} alt="logo" width={40} /> Atelier Génie Mécanique <span>(25)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Télécommunication <span>(12)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Electronique <span>(5)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Instrumentation <span>(22)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Electrotechnique <span>(8)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Robotique & Automatisme <span>(14)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Asservissement <span>(14)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Impression 3D <span>(14)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle RDM <span>(14)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Informatique <span>(14)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> Salle Fibre Optique <span>(14)</span></a></li>
                                        <li><a><img src={logo} alt="logo" width={40} /> etc.... <span>(14)</span></a></li>

                                    </ul>
                                </div>

                                <h3 className="sidebar-title">Equipe de Recherches</h3>
                                <div className="sidebar-item recent-posts">
                                    <div className="post-item clearfix">
                                        <img src={logo} alt="logo" />
                                        <time datetime="2020-01-01">Equipe 1</time>
                                        <h4><a> Télécommunication, Electrique & Electronique </a></h4>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src={logo} alt="logo" />
                                        <time datetime="2020-01-01">Equipe 2</time>
                                        <h4><a>Matériaux, Energies Renouvelable & Innovation Technologique</a></h4>

                                    </div>

                                    <div className="post-item clearfix">
                                        <img src={logo} alt="logo" />
                                        <time datetime="2020-01-01">Equipe 3</time>
                                        <h4><a>Génie Mécanique, Mécatronique & Productique</a></h4>

                                    </div>

                                </div>

                                <h3 className="sidebar-title">Tags</h3>
                                <div className="sidebar-item tags">
                                    <Tags />
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}

export default PresentationLabTech;