import { Modal, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";

//importation des css
import '../../style/modal/modalExperience.css'
import axios from "axios";
import { useState } from "react";

const ModalJobs = ({ show, onHide, id }) => {

    const { register, handleSubmit, reset } = useForm()
    const [toast, setToast] = useState(false);

    const newJobs = (data) => {

        const field = {
            id: id,
            domaine: data.domaine,
            type: data.type,
            description: data.description,
            mission: data.mission,
            envTravail: data.envTravail,
            profil: data.profil,
            competence: data.competence,
            dossier: data.dossier,
            dateLimite: data.dateLimite
        }

        console.log(field)

        addJobs(field)

        setToast(true)

        reset()
    }

    const addJobs = async (data) => {

        try {

            await axios.put('https://labtech-bakoffice.ufrssmt.edu.ci/api/jobs/new_jobs/', data)

        } catch (error) {
            console.log(error)
        }

        // console.log(response)
    }

    const close = () => {

        onHide(false)
        reset()
    }
    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Offre : emploi - stage
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(newJobs)}>
                            <div className="inputZone">
                                <label htmlFor="">Domaine</label>
                                <select {...register('domaine')} >
                                    <option value="Information">Informatique</option>
                                    <option value="Electronique">Electronique</option>
                                    <option value="Automatique">Automatique</option>
                                    <option value="Electrotechnique">Electrotechnique</option>
                                </select>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Type</label>
                                <input type="text" placeholder="RECRUTEMENT SKAN TECHNOLOGIE" {...register('type')} />
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Description de l'Entreprise</label>
                                <textarea cols="30" rows="5" placeholder="L’entreprise SKAN Technologies, spécialiste en ingénierie Informatique, recrute 𝘂𝗻 𝘀𝘁𝗮𝗴𝗶𝗮𝗶𝗿𝗲 𝗱é𝘃𝗲𝗹𝗼𝗽𝗽𝗲𝘂𝗿 𝗝𝗮𝘃𝗮 (𝗦𝗽𝗿𝗶𝗻𝗴 𝗕𝗼𝗼𝘁)." {...register('description')}></textarea>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Vos missions</label>
                                <textarea cols="30" rows="5" placeholder="● Le développement des API sur la base d’un cahier de charges
● Assurer la maintenance des applications existantes" {...register('mission')}></textarea>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Profil</label>
                                <textarea cols="30" rows="5" placeholder="● Vous êtes diplômés d’une école d'ingénierie informatique, d’une université publique
● Vous avez de solides bases en Java et désirez les approfondir au sein d’une entreprise
" {...register('profil')}></textarea>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Vos compétences</label>
                                <textarea cols="30" rows="5" placeholder="● Java
● Spring Boot" {...register('competence')}></textarea>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Environnements de travail</label>
                                <textarea cols="30" rows="5" placeholder="- 𝗟𝗼𝗴𝗶𝗰𝗶𝗲𝗹 𝘀𝗲𝗿𝘃𝗲𝘂𝗿: PostgreSQL 9.X et PostGIS, Apache Tomcat 8.0, Oracle
- 𝗜𝗗𝗘: Eclipse
- 𝗢𝘂𝘁𝗶𝗹𝘀 𝘀𝘂𝗽𝗽𝗹é𝗺𝗲𝗻𝘁𝗮𝗶𝗿𝗲𝘀: Maven, GIT, Docker" {...register('envTravail')}></textarea>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">𝗗𝗼𝘀𝘀𝗶𝗲𝗿 𝗱𝗲 𝗰𝗮𝗻𝗱𝗶𝗱𝗮𝘁𝘂𝗿𝗲:</label>
                                <textarea cols="30" rows="5" placeholder="Faites parvenir votre CV à l’adresse 𝙟𝙤𝙚𝙡.𝙩𝙖𝙣𝙛𝙤𝙡𝙤@𝙨𝙠𝙖𝙣𝙘𝙞.𝙘𝙤𝙢" {...register('dossier')}></textarea>
                            </div>


                            <div className="inputZone">
                                <label htmlFor="">𝐃𝐚𝐭𝐞 𝐥𝐢𝐦𝐢𝐭𝐞:</label>
                                <input type="date" {...register('dateLimite')} />
                            </div>

                            <br /><br />

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className="inputZoneSubmit" style={{ marginRight: "10px" }}><label style={{ backgroundColor: "red", color: "white" }} onClick={() => close()}>Annuler</label></div>
                                <div className="inputZoneSubmit">
                                    <label htmlFor="envoyer">Publier</label>
                                    <input type="submit" id="envoyer" style={{ display: 'none' }} />
                                </div>
                            </div>

                            <div>
                                <Toast style={{ width: '100%' }} onClose={() => setToast(false)} show={toast} delay={10000} autohide>
                                    <Toast.Body style={{ backgroundColor: 'seagreen', padding: "10px", textAlign: "center", color: "white" }}>
                                        Votre poste a été ajouté avec succès
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalJobs;