import '../style/dashboard.css'
import logo from '../assets/logo.png'
import logo_cfit from '../assets/logo_cfit.jpg'
import PageAccueil from '../Admin/PageAccueil';
import PageNosAlumnis from '../Admin/PageNosAlumnis';
import PageChallenge from '../Admin/PageChallenge';
import PageNosEtudiants from '../Admin/PageNosEtudiants';
import { useState } from 'react';
import PageOffreEmploi from '../Admin/PageOffreEmploi';
import { FaUser } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import PageMessageVisiteur from '../Admin/PageMessageVisiteur';




const Dashboard = () => {

	const [pageActive, setPageActive] = useState(<PageAccueil />);
	const [pageActiveNom, setPageActiveNom] = useState('Accueil');
	const [barElement, setBarElement] = useState(false);

	const handlePage = (page, path) => {
		setPageActive(page)
		setPageActiveNom(path)
	}

	const handleBarElement = () => {
		setBarElement(barElement === true ? false : true)
	}

	return (
		<div className="Dashboard">
			<header class="header">
				<div class="header-content responsive-wrapper">
					<div class="header-logo">
						<a>
							<img src={logo} width={70} />
							<img src={logo_cfit} width={80} />
						</a>
					</div>

					<div class="header-navigation">
						<nav class="header-navigation-links">
							<a style={{ cursor: "pointer" }} onClick={() => handlePage(<PageAccueil />, 'Accueil')}> Accueil </a>
							<a style={{ cursor: "pointer" }} onClick={() => handlePage(<PageNosAlumnis />, 'Nos Alumnis')}> Nos Alumnis </a>
							<a style={{ cursor: "pointer" }} onClick={() => handlePage(<PageChallenge />, 'Challenge')}> Challenge </a>
							<a style={{ cursor: "pointer" }} onClick={() => handlePage(<PageNosEtudiants />, 'Nos étudiants')}> Nos étudiants </a>
							<a style={{ cursor: "pointer" }} onClick={() => handlePage(<PageOffreEmploi />, 'Offre emploi')}> Offre emploi </a>
							<a style={{ cursor: "pointer" }} onClick={() => handlePage(<PageMessageVisiteur />, 'Message des visiteurs')}> Message visiteur </a>
						</nav>
						<div class="header-navigation-actions">
							<a href="#" class="icon-button">
								<FaUser />
							</a>
							<a class="avatar">
								Admin
							</a>
						</div>
					</div>
					<a style={{ cursor: "pointer" }} onClick={() => handleBarElement()} class="button">
						<span style={{ display: "flex", alignItems: "center" }}> <FiMenu /> Menu</span>
					</a>
				</div>

			</header>
			<div  style={{ display: barElement === true ? 'initial' : "none" }}>
				<div style={{paddingBottom:"10px", paddingLeft:"20px", backgroundColor: "#40A2D8", cursor: "pointer" }} onClick={() => handlePage(<PageAccueil />, 'Accueil')}> Accueil </div>
				<div style={{paddingBottom:"10px", paddingLeft:"20px", backgroundColor: "#40A2D8", cursor: "pointer" }} onClick={() => handlePage(<PageNosAlumnis />, 'Nos Alumnis')}> Nos Alumnis </div>
				<div style={{paddingBottom:"10px", paddingLeft:"20px", backgroundColor: "#40A2D8", cursor: "pointer" }} onClick={() => handlePage(<PageChallenge />, 'Challenge')}> Challenge </div>
				<div style={{paddingBottom:"10px", paddingLeft:"20px", backgroundColor: "#40A2D8", cursor: "pointer" }} onClick={() => handlePage(<PageNosEtudiants />, 'Nos étudiants')}> Nos étudiants </div>
				<div style={{paddingBottom:"10px", paddingLeft:"20px", backgroundColor: "#40A2D8", cursor: "pointer" }} onClick={() => handlePage(<PageOffreEmploi />, 'Offre emploi')}> Offre emploi </div>
				<div style={{paddingBottom:"10px", paddingLeft:"20px", backgroundColor: "#40A2D8", cursor: "pointer" }} onClick={() => handlePage(<PageMessageVisiteur />, 'Message des visiteurs')}> Message visiteur </div>

			</div>
			<main className="main">
				<div className="responsive-wrapper">
					<div className="main-header">
						<h1>{pageActiveNom}</h1>
					</div>
					{/* PAGE ACTIVE */}
					<div className='conteneurPageActive'>
						{pageActive}
					</div>
				</div>
			</main>
		</div>
	)
}

export default Dashboard;