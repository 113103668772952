import { FaAngleRight } from 'react-icons/fa';
import broken from '../assets/broken.png'
const ErrorPage = () => {
    return (
        <div>
            <section className="error-404 section-padding">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <div className="error-block ">
                                <div className="throw-code">
                                    <h2>
                                        404
                                    </h2>
                                </div>
                                <div className="error-info">
                                    <h2 className="mb-2">On dirait que vous êtes perdu !</h2>
                                    <p className="mb-5">La page que vous recherchez n’est pas disponible.</p>
                                    <a href="/accueil" style={{color:"blue"}}>Retour À La Page D’accueil  <FaAngleRight/></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="broken-img mt-5 mt-lg-0">
                                <img src={broken} alt="broken 404" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ErrorPage;
