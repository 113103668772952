import { FaClock, FaMailBulk } from 'react-icons/fa';
import '../style/lireActualite.css'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../composant/Footer';


const PageDetailChallenge = () => {

    const [challenge, setChallenge] = useState([{ domaine: "", sujet: "" }]);


    const { id } = useParams()

    const navigate = useNavigate()

    useEffect(() => {

        const recu_challenge = async () => {

            const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/challenge/admin_affiche_reponse/${id}`)

            setChallenge(response.data['info'])
            console.log(response.data['info'])

        }

        recu_challenge()

    }, [id])


    return (
        <div className="lireActualite">

            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span onClick={() => navigate('/dashboard')} style={{ cursor: "pointer" }}>Dashboard</span>
            </header>

            <section class="single-block-wrapper section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div class="single-post">

                                <div class="post-body">
                                    <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                                        {challenge[0].domaine}
                                    </div>
                                    <div class="entry-content">
                                        <p>{challenge[0].sujet.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}</p>
                                    </div>
                                </div>
                            </div>

                            <div id="comments" class="comments-block block">
                                <h3 class="news-title">
                                    <span>Les réponses</span>
                                </h3>
                                <ul class="all-comments">
                                    {
                                        challenge[0].reponse && (
                                            challenge[0].reponse.map((data, index) => (
                                                <li key={index}>
                                                    <div class="comment">
                                                        <div class="comment-body">
                                                            <div class="meta-data">
                                                                <span class="commented-person-name">{data.nom}</span>
                                                                <span class="comment-hour d-block" style={{ marginLeft: "30px" }}><FaMailBulk style={{ marginRight: "10px" }} /><a href={`mailto:${data.email}`}><span style={{ color: "black" }}>{data.email}</span></a></span>
                                                                <span class="comment-hour d-block" style={{ marginLeft: "30px" }}><FaClock style={{ marginRight: "10px" }} /><span style={{ color: "black" }}>{data.temps}</span></span>
                                                                <span class="comment-hour d-block" style={{ marginLeft: "30px" }}>Spécialité : <span style={{ color: "black" }}>{data.specialite}</span> </span>
                                                                <span class="comment-hour d-block" style={{ marginLeft: "30px" }}>Niveau d'étude : <span style={{ color: "black" }}>{data.niveau}</span></span>
                                                            </div>
                                                            <div class="comment-content" style={{ marginLeft: "30px" }}>
                                                                <p style={{color:"black"}}>
                                                                    {data.reponse}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        )
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default PageDetailChallenge;