import { Carousel, Toast } from 'react-bootstrap';
import '../style/actualite.css'
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import logo_cfit from '../assets/logo_cfit.jpg'
import logo_ci_energie from '../assets/CI-Energies.jpg'
import logo_cie from '../assets/logo_cie.png'
import logo_skan from '../assets/logo_skan.png'
import logo_ciprel from '../assets/logo_ciprel.png'
import { useEffect, useState } from 'react';
import axios from 'axios';
import JobPost from './JobPost';
import DerniereActualite from './DerniereActualite';
import { useForm } from 'react-hook-form';


const Actualite = () => {

    const navigate = useNavigate()

    const [index, setIndex] = useState(0);
    const [articleData, setArticleData] = useState([{ file: "", titre: "", sousTitre: "", categorie: "" }, { file: "", titre: "", sousTitre: "", categorie: "" }, { file: "", titre: "", sousTitre: "", categorie: "" }, { file: "", titre: "", sousTitre: "", categorie: "" }]);
    const { register, handleSubmit, reset } = useForm()

    const [toast, setToast] = useState(false);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handlePage = (path, article) => {
        navigate(path, { state: { article: article } })
        window.scrollTo(0, 0);
    }


    useEffect(() => {

        const recu_data = async () => {
            try {

                let response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/afficher_actualite/')
                setArticleData(response.data['info'])

            } catch (error) {
                console.log(error)
            }
        }

        recu_data()

    }, []);

    const messageVisiteur = (data) => {
        const field = {
            nom: data.nom,
            email: data.email,
            sujet: data.sujet,
            message: data.message
        }
        setToast(true)
        execu(field)

        reset()
    }

    const execu = async (data) => {
        try {

            await axios.post('https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/ajouter_message_visiteur/', data,{headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}})
            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="Actualite">
            <section id="hero" className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column justify-content-center">
                            <h1 >Laboratoire de technologie de UFR SSMT UFHB</h1>
                            <p>
                                <div className="text-center text-lg-start">
                                    <a onClick={() => handlePage("/Accueil/visite-guider-laboratoire-de-technologie-université-Felix-houphout-Boigny")} className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center" style={{ cursor: "pointer" }}>
                                        <span>Visiter le laboratoire</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </p>

                        </div>
                    </div>
                </div>
            </section>
            <br /><br />

            {/* info new */}
            <h4 className="trending-bar-title" style={{ marginLeft: "10px" }}>A la une</h4><br />
            <Carousel activeIndex={index} onSelect={handleSelect}
                controls={false}
                indicators={false}
                interval={1200}
                style={{ marginLeft: "60px" }}
            >
                {
                    articleData.map((data, index) => {
                        return (
                            <Carousel.Item key={index} style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${data.titre}`, data._id)}>
                                <h5 style={{ marginBottom: "0", lineHeight: "20px", fontSize: "15px" }}>{data.titre}</h5>
                            </Carousel.Item>
                        )
                    })
                }

            </Carousel>

            <br />

            <section className="featured-posts">

                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-6 col-xs-12 col-lg-4">
                            <div className="featured-slider mr-md-3 mr-lg-3">
                                <div className="item" style={{ backgroundImage: `url(https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleData[0].file})`, cursor: "pointer" }} onClick={() => handlePage(`/actualité/${articleData[0].titre}`, articleData[0]._id)}>
                                    <div className="post-content">
                                        <a className="post-cat bg-primary" >{articleData[0].categorie}</a>
                                        <h2 className="slider-post-title" style={{ color: "white", lineHeight: "20px" }}>
                                            <a >{articleData[0].titre}</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12 col-lg-4">
                            <div className="featured-slider mr-lg-3">
                                <div className="item" style={{ backgroundImage: `url(https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleData[1].file})`, cursor: "pointer" }} onClick={() => handlePage(`/actualité/${articleData[1].titre}`, articleData[1]._id)}>
                                    <div className="post-content">
                                        <a className="post-cat bg-danger">{articleData[1].categorie}</a>
                                        <h2 className="slider-post-title" style={{ color: "white", lineHeight: "20px" }}>
                                            <a>{articleData[1].titre}</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-4">
                            <div className="row mt-3 mt-lg-0">
                                <div className="col-lg-12 col-xs-12 col-sm-6 col-md-6">
                                    <div className="post-featured-style" style={{ backgroundImage: `url(https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleData[2].file})`, cursor: "pointer" }} onClick={() => handlePage(`/actualité/${articleData[2].titre}`, articleData[2]._id)}>
                                        <div className="post-content">
                                            <a className="post-cat bg-success">{articleData[2].categorie}</a>
                                            <h2 className="post-title" style={{ color: "white", lineHeight: "20px" }}>
                                                <a>{articleData[2].titre}</a>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xs-12 col-sm-6 col-md-6">
                                    <div className="post-featured-style" style={{ backgroundImage: `url(https://labtech-bakoffice.ufrssmt.edu.ci/images/${articleData[3].file})`, cursor: "pointer" }} onClick={() => handlePage(`/actualité/${articleData[3].titre}`, articleData[3]._id)}>
                                        <div className="post-content">
                                            <a className="post-cat bg-info">{articleData[3].categorie}</a>
                                            <h2 className="post-title" style={{ color: "white", lineHeight: "20px" }}>
                                                <a>{articleData[3].titre}</a>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br />

            {/* derniere actualité */}
            <DerniereActualite />

            {/* Job post */}
            <JobPost />

            <div>
                {/* NOS PARTENAIRS */}

                <section id="clients" className="clients">

                    <div className="container" data-aos="fade-up">

                        <header className="section-header">
                            <h2>Nos partenaires </h2>
                        </header>

                        <div className="clients-slider swiper">
                            <div className="swiper-wrapper align-items-center" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <div className="swiper-slide"><img src={logo_cfit} className="img-fluid" alt="logo cfit iii" /></div>
                                <div className="swiper-slide"><img src={logo_cie} className="img-fluid" alt="logo cie" /></div>
                                <div className="swiper-slide"><img src={logo_ciprel} className="img-fluid" alt="logo ciprel" /></div>
                                <div className="swiper-slide"><img src={logo_ci_energie} className="img-fluid" alt="logo ci energie" /></div>
                                <div className="swiper-slide"><img src={logo_skan} className="img-fluid" alt="logo skan" /></div>
                            </div>
                        </div>
                    </div>

                </section>


                {/* <!-- ======= Contact Section ======= --> */}
                <section id="contact" className="contact">
                    <div className="container">
                        <br /><br />
                        <div className="section-title">
                            <header className="section-header">
                                <h2>Contact</h2>
                            </header>
                        </div>
                    </div>

                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.450841884598!2d-3.9900705999999997!3d5.3479473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1eb152f55f923%3A0xcda90708fb7ed3d6!2sIMERTEL!5e0!3m2!1sfr!2sci!4v1705965681809!5m2!1sfr!2sci" width="600" height="450" style={{ border: "0", width: "100%", height: "350px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className="container">
                        <div className="row mt-5">

                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="address">
                                        <h4>Emplacement:</h4>
                                        <p>82X5+5XF, Université Félix-Houphouët-Boigny, Abidjan, <br /> Abidjan Côte d'Ivoire</p>
                                    </div>

                                    <div className="email">
                                        <h4>Email:</h4>
                                        <p>info@ufrssmt.edu.ci</p>
                                    </div>

                                    <div className="phone">
                                        <h4>Téléphone:</h4>
                                        <p>05 56 14 00 71</p>
                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-8 mt-5 mt-lg-0">

                                <form role="form" className="php-email-form" onSubmit={handleSubmit(messageVisiteur)}>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Nom" required {...register('nom')} />
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="Email" required {...register('email')} />
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Sujet" required {...register('sujet')} />
                                    </div>
                                    <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" rows="5" placeholder="Message" required {...register('message')}></textarea>
                                    </div>
                                    <Toast style={{ width: '100%' }} onClose={() => setToast(false)} show={toast} delay={10000} autohide>
                                        <Toast.Body style={{ backgroundColor: 'seagreen', padding: "10px", textAlign: "center", color: "white" }}>
                                            Votre message a été ajouté avec succès
                                        </Toast.Body>
                                    </Toast>
                                    <div className="text-center"><button type="submit">Envoyer message</button></div>
                                </form>

                            </div>

                        </div>

                    </div>
                </section>
                {/* <!-- End Contact Section --> */}


            </div>

            <Footer />
        </div>
    )
}

export default Actualite;