import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import '../style/AnswerChallenge.css'
import Footer from "./Footer";
import { Toast } from "react-bootstrap";

const AnswerChallenge = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [repondreChallenge, setRepondreChallenge] = useState([{domaine: "", sujet:""}]);
    const { register, handleSubmit, reset } = useForm()

    const [toastChallenge, setToastChallenge] = useState(false);

    useEffect(() => {

        const recu = async () => {

            const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/challenge/repondre_challenge/${state.id}`)
            setRepondreChallenge(response.data['info'])

        }

        recu()

    }, [state])

    const handleChallenge = (data) => {

        const field = {
            id: state.id,
            nom: data.nom,
            email: data.email,
            specialite: data.specialite,
            niveau: data.niveau,
            reponse: data.reponse
        }
        execChallenge(field)
        setToastChallenge(true)
        reset()
    }

    const execChallenge = async (data) =>{
       await axios.post('https://labtech-bakoffice.ufrssmt.edu.ci/api/challenge/ajouter_reponse_challenge/', data,{headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}})
    }

    return (
        <div>
            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span onClick={() => navigate('/accueil')} style={{ cursor: "pointer" }}>Accueil</span> <MdKeyboardDoubleArrowRight /> <span style={{ cursor: "pointer" }}>Challenge</span>
            </header>

            <div className="conteneurReponseChallenge">
                <br />
                <div className="" >
                    {
                        repondreChallenge.map((data, index) => {
                            return (
                                <div key={index}>
                                    <div style={{fontSize:"22px", fontWeight:"bold"}}> {data.domaine} </div>
                                    <hr />
                                    <div> {data.sujet.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)} </div>
                                </div>
                            )
                        })
                    }
                </div>
<br /><br />
                <div class="comment-form ">
                    <h3 class="title-normal">Répondre au challenge </h3>
                    <form onSubmit={handleSubmit(handleChallenge)}>
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <input class="form-control" name="name" id="name" placeholder="Nom & prénoms" type="text" required {...register('nom')} />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input class="form-control" name="email" id="email" placeholder="Email" type="email" required {...register('email')} />
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <input class="form-control" name="specialite" id="specialite" placeholder="Votre spécialité" type="text" required {...register('specialite')} />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input class="form-control" name="niveau" id="niveau" placeholder="Votre niveau d'étude" type="text" required {...register('niveau')} />
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea class="form-control required-field" id="message" placeholder="Message" rows="10" required {...register('reponse')}></textarea>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <button class="comments-btn btn btn-primary " type="submit">Valider</button>
                            </div>

                            {/* reponse challenge */}
                            <Toast class="col-md-12" style={{ width: '100%' }} onClose={() => setToastChallenge(false)} show={toastChallenge} delay={10000} autohide>
                                <Toast.Body style={{ backgroundColor: 'seagreen', padding: "10px", textAlign: "center", color: "white" }}>
                                Votre réponse a été ajoutée avec succès.
                                </Toast.Body>
                            </Toast>
                        </div>
                    </form>

                    <br />

                </div>
            </div>

<Footer/>
        </div>
    );
}

export default AnswerChallenge;
