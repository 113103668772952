import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

//importation des css
import '../../style/modal/modalExperience.css'
import axios from "axios";

const ModalProjet = ({ show, onHide, id }) => {

    const { register, handleSubmit, reset } = useForm()

    const newEperience = (data) => {

        const field = {
            id: id,
            nom: data.nom,
            detail: data.detail,
            competence: data.competence,
            debut: data.debut,
            fin: data.fin
        }

        console.log(field)

        addExperience(field)

        reset()
    }

    const addExperience = async (data) => {
        
        try {

            await axios.put('https://labtech-bakoffice.ufrssmt.edu.ci/api/projet/new_projet/', data)
            
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Projet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(newEperience)}>
                            <div className="inputZone">
                                <label htmlFor="">Nom du projet</label>
                                <input type="text" placeholder="CIPREL" {...register('nom')} />
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Description</label>
                                <textarea rows="5" placeholder="description..." {...register('detail')}></textarea>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Compétence</label>
                                <input type="text" placeholder="Compétence (ex: management de projet)" {...register('competence')} />
                            </div>

                            <header>Informations supplémentaires</header>

                            <div className="inputZone">
                                <label htmlFor="">Date de début</label>
                                <input type="date" {...register('debut')} />
                            </div>
                            <div className="inputZone">
                                <label htmlFor="">Date de fin</label>
                                <input type="date" {...register('fin')} />
                            </div>

                            <div className="inputZoneSubmit">
                                <label htmlFor="envoyer">Ajouter</label>
                                <input type="submit" id="envoyer" style={{ display: 'none' }} />
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ModalProjet;