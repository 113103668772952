import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Footer from "../composant/Footer";

const PageDetailJobs = () => {

    const { id } = useParams()

    // console.log(id)

    const [jobInfo, setJobInfo] = useState([{ id: "", type: "", description: "", mission: "", envTravail: "", profil: "", competence: "", dossier: "", dateLimite: "" }]);
    const navigate = useNavigate()

    useEffect(() => {

        const recuJobs = async () => {

            const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/jobs/detail_jobs/${id}`)

            setJobInfo(response.data['info'])

        }

        recuJobs()

    }, [id])

    return (
        <div>
            <header style={{ backgroundColor: "#0e69b0", padding: "20px", fontSize: "18px", fontWeight: "100", fontFamily: "sans-serif", color: "white" }}>
                <span onClick={() => navigate('/dashboard')} style={{ cursor: "pointer" }}>Dashboard</span> <MdKeyboardDoubleArrowRight /> <span style={{ cursor: "pointer" }}>Jobs info</span>
            </header>
            <section class="section-padding" style={{ textAlign: "justify" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <h3 style={{ padding: "0", margin: "0" }}>{jobInfo[0].type}</h3>
                            <hr />
                            <h4>Description l'Entreprise</h4>
                            {jobInfo[0].description && (
                                <p>
                                    {jobInfo[0].description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
                                </p>
                            )}
                            <br /><br />


                            <h4>Profil</h4>
                            {
                                jobInfo[0].profil && (
                                    <p>
                                        {jobInfo[0].profil.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
                                    </p>
                                )
                            }

                            <br /><br />

                            <h4>Mission</h4>

                            {jobInfo[0].mission && (
                                <p>
                                    {jobInfo[0].mission.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
                                </p>
                            )}

                            <br /><br />

                            <h4>Compétences</h4>

                            {jobInfo[0].competence && (
                                <p>
                                    {jobInfo[0].competence.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
                                </p>
                            )}

                            <br /><br />

                            <h4>Dossier candidature</h4>

                            {jobInfo[0].dossier && (
                                <p>
                                    {jobInfo[0].dossier.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
                                </p>
                            )}

                            <br /><br />

                            <h4>Date limite : {jobInfo[0].dateLimite}</h4>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default PageDetailJobs;