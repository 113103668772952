import axios from "axios";
import { useEffect, useState } from "react";
import logo from '../assets/logo img.png'
import { useNavigate } from "react-router-dom";

const ListeAlumnis = () => {

    const [infoAlumni, setInfoAlumni] = useState([{_id:"", nom:"", filiere:"", photo:""}]);
    const navigate = useNavigate()

    useEffect(() => {

        const recuAlumnis = async () => {
            const response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/afficher_alumni/')
            setInfoAlumni(response.data['info'])
        }

        recuAlumnis()

    }, [])

    const handleChangePage = (path, id) => {
        navigate(path, {state: {id}})
        window.scrollTo(0, 0);
    }

    return (
        // <div className="ListeAlumnis">
            <div class="col-lg-4 col-md-8 col-sm-12 col-xs-12">
                <div class="sidebar sidebar-right">
                    <div class="widget">
                        <h3 class="news-title">
                            <span>Nos Alumnis</span>
                        </h3>
                        <div class="post-list-block">
                            <div class="review-post-list">
                                {
                                    infoAlumni.map((data, index) => {
                                        return (
                                            <div class="top-author" key={index} style={{cursor: "pointer"}} onClick={() =>handleChangePage('/Accueil/compte-utilisateur-alumni/', data._id)}>
                                                <img src={data.photo !==undefined ? (`https://labtech-bakoffice.ufrssmt.edu.ci/profil_images/${data.photo}`) : logo} alt="author-thumb" width={100} />
                                                <div class="info">
                                                    <h4 class="name"><a>{data.nom}</a></h4>
                                                    <ul class="list-unstyled">
                                                        <li>{data.filiere}</li>
                                                        <li>{data.promotion}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    )
}

export default ListeAlumnis;