import React from 'react';
import Footer from './Footer';
import { Card } from 'react-bootstrap';

const NosFormations = () => {
    return (
        <div>

            <div style={{ margin: "10px", marginTop: "0", paddingTop: "90px", textAlign: "justify" }}>
                <h3>Formations proposées par le Laboratoire de Technologie</h3>

                Le Laboratoire de Technologie de l'Université Félix Houphouet-Boigny de Cocody offre une gamme variée de formations axées sur les domaines technologiques émergents. Ces formations sont conçues pour répondre aux besoins du marché du travail tout en offrant aux étudiants une base solide de connaissances théoriques et pratiques.
            </div>
            <Card className='cardAlumnis' >
                <Card.Body>
                    <h3 className='titreParcours'>
                        <div> Parcours initial professionnel</div>
                        <div style={{ backgroundColor: "red", borderRadius: "5px", color: "white", padding: "10px", fontSize: "12px", cursor: "pointer" }}>Fiche de préinscription</div>
                    </h3>
                    <p>
                        <ul>
                            <li>
                                <h4>EEAI : Electronique Electrotechnique Automatique & Informatique</h4>
                                <ul>
                                    <li><h5>Conditions d'accès</h5>
                                        <ul>
                                            <li>Bac+1 de Physique chimie à l'UFR SSMT</li>
                                            <li>Licence 3 EEAI pour le cycle Master</li>
                                        </ul>
                                    </li>

                                </ul>
                            </li>
                            <br /><br />
                            <li><h4>EEM : Energétique Electro-Mécanique</h4>
                                <ul>
                                    <li><h5>Conditions d'accès</h5>
                                        <ul>
                                            <li>Bac+1 de physique chimie à l'UFR SSMT</li>
                                            <li>Licence 3 EEM pour le cycle Master</li>
                                        </ul></li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <h3 className='titreParcours'><div>Parours Professionnel</div> <div style={{ backgroundColor: "red", borderRadius: "5px", color: "white", padding: "10px", fontSize: "12px", cursor: "pointer" }}>Fiche de préinscription</div></h3>
                    <p>
                        <ul>
                            <li><h4>IME : Ingénierie Mécatronique & Energétique</h4>
                                <ul>
                                    <li><h5>Conditions d'accès</h5>
                                        <ul>
                                            <li>Bac scientifique</li>
                                            <li>BTS</li>
                                            <li>DUT</li>
                                            <li>Licences</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <br /><br />
                            <li><h4>IRST : Ingénierie Réseaux & Systèmes de Télécommunication</h4>
                                <ul>
                                    <li><h5>Conditions d'accès</h5>
                                        <ul>
                                            <li>Bac scientifique</li>
                                            <li>BTS</li>
                                            <li>Licences</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </p>


                    <h3 className='titreParcours'><div>Master Spécialisé</div> <div style={{ backgroundColor: "red", borderRadius: "5px", color: "white", padding: "10px", fontSize: "12px", cursor: "pointer" }}>Fiche de préinscription</div></h3>
                    <p>
                        <ul>
                            <li><h4>EST : Electronique & Systèmes de Télécommunication</h4>
                                <ul>
                                    <li><h5>Conditions d'accès</h5>
                                        <ul>
                                            <li>Licence 3 Physique, </li>
                                            <li>Physique, </li>
                                            <li>EEAI, </li>
                                            <li>EEM,</li>
                                            <li>Licence équivalent</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li><h4>PSA : Physique du Solide & Applications</h4>
                                <ul>
                                    <li><h5>Conditions d'accès</h5>
                                        <ul>
                                            <li>Licence 3 physique</li>
                                            <li>EEAI, </li>
                                            <li>EEM</li>
                                            <li>Licence équivalentes</li></ul></li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                </Card.Body>

            </Card>


            <Footer />
        </div>
    );
}

export default NosFormations;
