import React from 'react';
import Footer from './Footer';

import photo_alumni_1 from '../assets/img_labtech/alumni/1.jpeg'
import photo_alumni_2 from '../assets/img_labtech/alumni/2.jpg'
import photo_alumni_3 from '../assets/img_labtech/alumni/3.jpg'
import photo_alumni_4 from '../assets/img_labtech/alumni/4.jpg'
import '../style/nosAlumnis.css'
import { Card } from 'react-bootstrap';
import { TfiWorld } from "react-icons/tfi";
import { FaFacebook, FaLinkedin } from 'react-icons/fa';

const NosAlumnis = () => {
    return (
        <div>

            <div style={{margin:"10px",marginTop:"0",paddingTop:"90px", textAlign:"justify"}}>La plateforme Alumni est un outil collaboratif à destination des étudiants, diplômés, formateurs et recruteurs. Elle vise à offrir un réseau en quelques clics afin de créer une véritable communauté dans l’esprit du Laboratoire de technologie LabTech, en aidant et orientant les nouveaux élèves dans leurs études supérieures ou vers l’insertion professionnelle, le tout, en restant connectés aux grands projets de l’école </div>

            <Card className='cardAlumnis'>
                <Card.Body>
                    <section class="section-padding" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <h4 class="mb-4">M. Anicet AMANI</h4>
                                    <p className='titreAlumnis'>PDG SKAN TECHNOLOGIE</p>

                                    <p>
                                        <div>
                                            <TfiWorld /> site officiel : <a href="https://skanticket.com/#/" target='_bank'>skanticket.com</a><br />
                                            <FaLinkedin style={{ color: "blue" }} /> LinkedIn : <a href="https://www.linkedin.com/in/anicet-amani-2b60605a/" target='_bank'>Anicet Amani</a><br />
                                            <FaFacebook style={{ color: "blue" }} /> Facebook : <a href="https://www.facebook.com/anicet.amani" target='_bank'>Anicet Amani</a>

                                        </div>
                                        <div style={{ fontSize: "12px", marginLeft: "20px" }}><strong>#Skan Technologies</strong> est une startup ivoirienne spécialisée dans le développement d’applications grand public et dirigé par Anicet Amani, Skan Technologies propose plusieurs solutions dont les deux principales sont les deux applications suivantes : <br />

                                            🎫<strong>#SkanTicket</strong> <a href="https://skanticket.com/#/" target='_bank'>(http://skanticket.com)</a>: Application de billetterie électronique qui est utilisée principalement par les Cinémas Majestic. SkanTicket permet d’acheter des tickets de spectacle en ligne par mobile money. <br />

                                            👨⚕️ <strong>#SkanMed </strong> <a href="https://skanmed.com" target='_bank'>(https://skanmed.com)</a>: Application de téléconsultation médicale, SkanMed est conventionné sous le numéro 1173 du 29 septembre 2020 par le Ministère de la Santé et de l’hygiène publique (MSHP) de la Côte d’Ivoire.

                                            <a href="https://www.linkedin.com/pulse/skan-technologie-linnovation-digitale-red%C3%A9finie-en-c%C3%B4te-konan-zy5te/" target='_bank'>En savoir plus</a>
                                        </div>
                                    </p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <img src={photo_alumni_1} alt="" class="img-fluid rounded tailleMaxImages" />
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-lg-6 col-md-6">
                                    <img src={photo_alumni_2} alt="" class="img-fluid rounded tailleMaxImages" />
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <h4 class="mb-4">M. AKA Stephane</h4>
                                    <p className='titreAlumnis'>Enseignant-Chercheur <br /> UNIVERSITE FELIX HOUPHOUET BOIGNY</p>

                                    <div>
                                        <FaLinkedin style={{ color: "blue" }} /> LinkedIn : <a href="https://www.linkedin.com/in/aka-stephane-koffi-9078b75b/" target='_bank'>AKA Stephane</a><br />

                                    </div>

                                    <div style={{ fontSize: "12px", marginLeft: "20px" }}>

                                        🎫<strong>#LE PRIX D'EXCELLENCE -TICLE PRIX D'EXCELLENCE -TIC</strong> <br />

                                        Émis par PRÉSIDENCE DE LA RÉPUBLIQUE DE COTE D IVOIRE · août 2014<br />

                                        <strong>#Former les étudiants sur des thématiques en technologie (conception de systèmes de production )</strong><br />
                                        Compétences : Énergie solaire thermique · Électronique · Informatique décisionnelle · Efficacité énergétique · Automate programmable industriel · Automatisation des processus robotisés (APR) · Développement de leadership


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="section-padding" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <h4 class="mb-4">M. F. Didier NENE , MBA, Msc. Ing</h4>
                                    <p className='titreAlumnis'>Transport et Téléconduite de réseau électrique</p>

                                    <p>
                                        <div>

                                            <FaLinkedin style={{ color: "blue" }} /> LinkedIn : <a href="https://www.linkedin.com/in/f-didier-nene-mba-msc-ing-369071134/" target='_bank'>F. Didier NENE , MBA, Msc. Ing</a><br />


                                        </div>
                                        <div style={{ fontSize: "12px", marginLeft: "20px" }}>
                                            <strong>Chef de projet</strong> expérimenté dans le domaine de l’énergie avec une expérience avérée dans le secteur de la recherche. Compétent dans les domaines des réseaux intelligents, de l’ingénierie automobile, de la gestion, de l’apprentissage automatique et du leadership. <br />

                                            🎫<strong>#Licences et certifications :</strong><br />
                                            <a href="https://www.linkedin.com/company/enernex/" target='_bank'>EnerNex</a> : Certificat de formation avancée sur le logiciel POWER FACTORY<br />

                                            <a href="https://www.linkedin.com/company/bcp-busarello-cott-partner-ag/" target='_bank'>PSI NEPLAN AG </a>: Certificat de formation sur le logiciel NEPLAN <br />

                                            <a href="hhttps://www.linkedin.com/in/f-didier-nene-mba-msc-ing-369071134/details/certifications/" target='_bank'>En savoir plus</a>
                                        </div>
                                    </p>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <img src={photo_alumni_3} alt="" class="img-fluid rounded tailleMaxImages" />
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-lg-4 col-md-4">
                                    <img src={photo_alumni_4} alt="" class="img-fluid rounded tailleMaxImages" />
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <h4 class="mb-4">M. Ernest Youe Bi</h4>
                                    <p className='titreAlumnis'>Ingénieur Conseil en management industriel chez Bureau d'Etudes IMA</p>

                                    <div>
                                        <FaLinkedin style={{ color: "blue" }} /> LinkedIn : <a href="https://www.linkedin.com/in/ernest-youe-bi-279a4647/" target='_bank'>Ernest Youe Bi</a><br />
                                        <FaFacebook style={{ color: "blue" }} /> Facebook : <a href="https://www.facebook.com/ernest.youebi" target='_bank'>Ernest Youe Bi</a>

                                    </div>

                                    <div style={{ fontSize: "12px", marginLeft: "20px" }}>

                                        <p>Ernest YOUE BI YOUE est un ingénieur industriel gradué en 1998, Maître es-Sciences et Techniques EEAI de l’UFR SSMT, Filière Professionnelle Longue Sciences de l’Ingénieur (FPL-SI) de l’université de COCODY (République de Côte d’Ivoire).</p>

                                        <p>Après avoir été ingénieur industriel à la Multinationale MAKIBER (groupe DRAGADOS), il est consultant en management industriel (Opérations et Systèmes de Décision), expert QHSE et spécialiste des contrats publics.</p>

                                        <p>Ses Travaux et Activités de conseils, études et formation sont focalisés sur la conception et la gestion des systèmes et procédés industriels et des infrastructures, ainsi que sur la modélisation et l’analyse prospective en milieu industriel, notamment les industries manufacturières.</p>

                                        <p>Ses intérêts principaux de consultance et de formation portent sur le développement de concepts, de méthodologies pour pérenniser les processus de création de valeur des entreprises. Il a une vaste expérience de conseil, d’études et de formation avec l’industrie (Partenaires du cabinet IMA).</p>

                                        <strong>Spécialités:</strong>
                                        <ul>
                                            <li>Optimisation de la maintenance préventive des équipements et des stocks de pièces de rechange.</li>
                                            <li>Conduite de projets GMAO.</li>
                                            <li>Mise en place d’un plan de progrès de maintenance.</li>
                                            <li>Etude TRS, Analyse Fonctionnelle, AMDEC, SIPOC, Six Sigma, Arbre de défaillance, diagrammes de fiabilité, Maîtrise Statistique des Procédés MSP, Lean Production (SMED, JAT, 5S, VSM, KAIZEN, TPM, TRIZ,…)</li>
                                            <li>Assurance de l’amélioration continue d’un Système de Management QHSE.</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Card.Body>
            </Card>

            <Footer />
        </div>
    );
}

export default NosAlumnis;
