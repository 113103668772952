import { Modal, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";

//importation des css
import '../../style/modal/modalExperience.css'
import axios from "axios";
import { useState } from "react";

const ModalExperience = ({ show, onHide, id }) => {

    const {register, handleSubmit, reset} = useForm()
    const [toast, setToast] = useState(false);

    const newEperience = (data) => {

        const field = {
            id: id,
            societe: data.societe,
            poste: data.poste,
            contrat: data.contrat,
            ville: data.ville,
            debut: data.debut,
            fin: data.fin,
            competence: data.competence            
        }

        // console.log(field)

        addExperience(field)
        setToast(true)

        reset()
    }

    const addExperience = async (data) => {
       
        try {

            await axios.put('https://labtech-bakoffice.ufrssmt.edu.ci/api/experience/new_experience/', data)
            
        } catch (error) {
            console.log(error)
        }

    }

    const closeModal = ()=>{
        reset()
        onHide(false)
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nouvelle expérience
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(newEperience)}>
                            <div className="inputZone">
                                <label htmlFor="">Société</label>
                                <input type="text" placeholder="CIPREL" {...register('societe')}/>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Poste</label>
                                <input type="text" placeholder="Engénieur électromécanique" {...register('poste')}/>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Type contrat</label>
                                <select {...register('contrat')}>
                                    <option value="Stage école">Stage école</option>
                                    <option value="Stage perfectionnement">Stage perfectionnement</option>
                                    <option value="CDI">CDI</option>
                                    <option value="CDD">CDD</option>
                                </select>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Ville</label>
                                <input type="text" placeholder="Abidjan" {...register('ville')}/>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Date de début</label>
                                <input type="date" {...register('debut')} />
                            </div>
                            <div className="inputZone">
                                <label htmlFor="">Date de fin</label>
                                <input type="date" {...register('fin')}/>
                            </div>

                            <div className="inputZone">
                                <label htmlFor="">Compétence</label>
                                <textarea id="" rows="5" {...register('competence')}></textarea>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="inputZoneSubmit" onClick={() => closeModal()}>
                                    <label htmlFor="" style={{ backgroundColor: "red", marginRight: "20px", width: "160px" }}>Annuler</label>
                                </div>
                                <div className="inputZoneSubmit">
                                    <label htmlFor="envoyer" style={{ width: "160px" }}>Publier</label>
                                    <input type="submit" id="envoyer" style={{ display: 'none' }} />
                                </div>
                            </div>

                            <div>
                                <Toast style={{width:'100%'}} onClose={() => setToast(false)} show={toast} delay={10000} autohide>
                                    <Toast.Body style={{ backgroundColor: 'seagreen', padding: "10px", textAlign: "center", color: "white" }}>
                                        Votre poste a été ajouté avec succès
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ModalExperience;