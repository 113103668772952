import { useState } from "react";
import ModalJobs from "../composant/modals/ModalJobs";
import { useEffect } from "react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PageOffreEmploi = () => {

    const navigate = useNavigate()

    const [jobModal, setJobModal] = useState(false);
    const [offreEmploi, setOffreEmploi] = useState([{}]);
    const [displayNon, setDisplayNon] = useState();

    useEffect(() => {
        const recu = async () => {

            try {

                const response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/admin/afficher_jobs/')
                setOffreEmploi(response.data['info'])

            } catch (error) {
                console.log(error)
            }

        }

        recu()
    }, [])

    const handlePage = (path, article) => {
        navigate(path, { state: { article: article } })
    }

    const handleDelete = (data, index) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Voulez-vous supprimer cet offre?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => {
                        deleteConfirm(data)
                        setDisplayNon(index)
                    }
                },
                {
                    label: 'non',
                    // onClick: () => alert('You clicked No')
                }
            ]
        });
    }

    const deleteConfirm = async (data) => {
        const response = await axios.delete(`https://labtech-bakoffice.ufrssmt.edu.ci/api/admin/delete_jobs/${data}`)
        console.log(response)
    }


    return (
        <div className='PageAccueil'>
            <div class="vertical-tabs">
                <a class="active" style={{ cursor: "pointer" }} onClick={() => setJobModal(true)}>Publier offre</a>
            </div>
            <div className="content">
                {/* <div class="content-panel">
                <div class="vertical-tabs">
                        <a class="active" style={{cursor:"pointer"}} onClick={() => setArticle(true)}>Publier article</a>
                    </div><br />
                    <div class="vertical-tabs">
                        <a class="active">Tous</a>
                        <a >Article activé</a>
                        <a >Article desactivé</a>
                    </div>
                </div> */}
                <div className="content-main">
                    <div className="card-grid">

                        {
                            offreEmploi.map((data, index) => {
                                return (
                                    <article className="card" key={index} style={{ display: displayNon === index && "none" }}>
                                        <div className="card-header">
                                            <div>
                                                <h6>{data.domaine}</h6>
                                            </div>
                                            <label className="toggle" style={{ cursor: "pointer", color: "red", fontSize: "22px" }}>
                                                <MdDelete onClick={() => handleDelete(data._id, index)} />
                                            </label>
                                        </div>
                                        <div className="card-body">
                                            <p>{data.type}</p>
                                        </div>
                                        <div className="card-footer">
                                            <a style={{ cursor: "pointer" }} href={`/dashboard/job-a-pouvoir/${data.type}/${data._id}`}>Voir</a>
                                        </div>
                                    </article>
                                )
                            })
                        }
                    </div>



                </div>
            </div>

            <ModalJobs
                show={jobModal}
                onHide={() => setJobModal(false)}
            // id={admin.id}
            />
        </div>
    );
}

export default PageOffreEmploi;
