import axios from "axios";
import { useState } from "react";
import { Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import img_connexion from '../assets/auth.png'
import '../style/connexion.css';
import { useNavigate } from "react-router-dom";

const Inscription = () => {

    const navigate = useNavigate()
    const { register, handleSubmit } = useForm()
    const [msgToast, setMsgToast] = useState(false);
    const [displaySection, setDisplaySection] = useState('initial')
    const [messageServeur, setMessageServeur] = useState("Vous ne pouvez pas vous inscire veuillez réessayer ulterieurement");

    const inscription = (data) => {

        const field = {
            nom: data.nom,
            email: data.email,
            phone: data.phone,
            filiere: data.filiere,
            promotion: data.promotion,
            niveau: data.niveau,
            diplome: data.diplome,
            societe: data.societe,
            experience: data.experience,
            mdp: data.mdp,
            alumni: data.ouiAlumni === "oui" ? "oui" : "non"
        }

        insertAxios(field)

    }

    const insertAxios = async (data) => {

        try {

            const response = await axios.post('https://labtech-bakoffice.ufrssmt.edu.ci/api/user/inscription/', data,{headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}})

            if (response.data['msg'] === "succès") {

                const id = response.data['id']
                localStorage.setItem('admin', id)
                navigate('/accueil', { state: { id: id } })

            } else if(response.data['msg'] === "Email existe déjà"){

                setMessageServeur("Email existe déjà")
                setMsgToast(true)

            } else {

                setMsgToast(true)

            }
            
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className="Inscription connexionPage" align="center">
           
            <img src={img_connexion} alt="logo connexion" className='iconDeux' />

            <div className='conteneurForm'>
                <form onSubmit={handleSubmit(inscription)}>

                    <div style={{ display: displaySection === 'initial' ? 'initial' : 'none' }}>
                        <div className='conteneurInput'>
                            <label htmlFor="nomInscrire">Nom & prénom : </label>
                            <input type="text" id='nomInscrire' {...register('nom')} required/>
                        </div>
                        <div className='conteneurInput'>
                            <label htmlFor="email">E-mail : </label>
                            <input type="email" id='email' {...register('email')} required/>
                        </div>
                        <div className='conteneurInput'>
                            <label htmlFor="phone">Téléphone : </label>
                            <input type="text" id='phone' {...register('phone')} />
                        </div>
                        <div className='conteneurInput'>
                            <label htmlFor="filere">Filière : </label>
                            <select id="filere" {...register('filiere')} required>
                                <option value="EEAI">Electronique Electrotechnique Automatique Informatique EEAI</option>
                                <option value="EEM">Energie Electromécanique EEM</option>
                                <option value="MPT">Mathématique Physique Technologie MPT</option>
                                <option value="MPCT" >Mathématique Physique Chimie Technologie MPT</option>
                            </select>
                        </div>
                        <div className='conteneurInput'>
                            <label htmlFor="promotion">Promotion : </label>
                            <input type="text" id='promotion' placeholder="2019-2020" {...register('promotion')} />
                        </div>

                        <div className="conteneurInputAlumnisChoix" style={{ marginBottom: "30px", marginTop: "-10px" }}>
                            <label htmlFor="" style={{ fontWeight: "bold" }}>Vous êtes un alumni* : </label><br />

                            <div className="elementInputChoix">
                                <input type="radio" name="alumni" id="oui" value="oui" {...register('ouiAlumni')} /> <label htmlFor="oui">Oui</label>
                                <input type="radio" name="alumni" id="non" value="non" {...register('ouiAlumni')} /> <label htmlFor="non">non</label>
                            </div>
                        </div>
                        <div className="conteneurSubmit" style={{ margin: "auto", cursor: "pointer" }} onClick={() => setDisplaySection('none')}>
                            suivant
                        </div>

                        <div style={{ textAlign: 'center', marginTop: "20px", marginBottom: "30px", marginLeft: "auto", color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate('/connexion')}>
                            connexion
                        </div>

                    </div>

                    {/* SCETION DEUX */}
                    <div style={{ display: displaySection === 'initial' ? 'none' : 'initial' }}>
                        <div className='conteneurInput'>
                            <label htmlFor="niveau">Niveau : </label>
                            <input type="text" id='niveau' {...register('niveau')} />
                        </div>
                        <div className='conteneurInput'>
                            <label htmlFor="diplome">Diplôme : </label>
                            <input type="text" id='diplome' {...register('diplome')} />
                        </div>
                        <div className='conteneurInput'>
                            <label htmlFor="societe">Domaine : </label>
                            <input type="text" id='societe' {...register('societe')} />
                        </div>
                        <div className='conteneurInput'>
                            <label htmlFor="experience">Expérience professionnel : </label>
                            <input type="text" id='experience' {...register('experience')} />
                        </div>
                        {/* <div className='conteneurInput'>
                            <label htmlFor="projet">Projet : </label>
                            <input type="text" id='projet' {...register('projet')} />
                        </div> */}
                        <div className='conteneurInput'>
                            <label htmlFor="mdpIscrire">Mot de passe : </label>
                            <input type="password" id='mdpIscrire' {...register('mdp')} required/>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div onClick={() => setDisplaySection('initial')} className="boutonRetourInscrire">
                                retour
                            </div>
                            <div className='conteneurSubmit'>
                                <label htmlFor="inscrire" style={{ cursor: "pointer" }}>Valider</label>
                                <input type="submit" name='fiofoodinscrire' id='inscrire' style={{ display: "none" }} />
                            </div>
                        </div>
                    </div>
                </form>
                <Toast style={{ width: '100%' }} onClose={() => setMsgToast(false)} show={msgToast} delay={10000} autohide>
                    <Toast.Body style={{ backgroundColor: 'red', padding: "10px", textAlign: "center", color: "white" }}>
                        {messageServeur}
                    </Toast.Body>
                </Toast>

            </div>


            {/* <img src={img_connexion} alt="logo connexion" className='connexion pageImage' /> */}
            <br /><br />
        </div>
    )
}

export default Inscription;