import { useEffect, useState } from "react"
import ListeAlumnis from "./ListeAlumnis"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import SpinnerCard from "./Spinner"

const DerniereActualite = () => {

    const navigate = useNavigate()

    const [actualites, setActualites] = useState([{ file: "", titre: "", sousTitre: "", categorie: "" }]);
    const [evenements, setEvenements] = useState([{ file: "", titre: "", sousTitre: "", categorie: "" }]);
    const [fetes, setFetes] = useState([{ file: "", titre: "", sousTitre: "", categorie: "" }]);
    const [dernierArticle, setDernierArticle] = useState([{ file: "", titre: "", sousTitre: "", categorie: "" }]);
    const [spinner, setSpinner] = useState(false);
    const [boutonSuivant, setBoutonSuivant] = useState(false);

    useEffect(() => {
        const recuDerniereActualite = async () => {
            const response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/derniere_actualite/')
            // console.log(response)

            if (response.data['msg1'] === "succès") {
                setActualites(response.data['info1'])

            }

            if (response.data['msg2'] === "succès") {
                setEvenements(response.data['info2'])

            }

            if (response.data['msg3'] === "succès") {
                setFetes(response.data['info3'])
            }

            if (response.data['msg4'] === "succès") {
                setDernierArticle(response.data['info4'])
            }
        }

        recuDerniereActualite()
    }, [])

    const handlePage = (path, article) => {
        navigate(path, { state: { article: article } })
        window.scrollTo(0, 0);
    }


    const handlePlusData = async () => {
        setSpinner(true)
        const taille = dernierArticle.length;
        
        const response = await axios.get(`https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/plus_contenu_dernier_article/${taille}`)
     
        console.log(response.data['info'])

        if (response.data['info'].length ===0) {
            setBoutonSuivant(true)
        } else {
            for (let index = 0; index < response.data['info'].length; index++) {
                dernierArticle.push(response.data['info'][index])            
             }
        }

        setSpinner(false)
    }

    return (
        <div className="DerniereActualite">
            {
                spinner === true && (
                    <SpinnerCard />
                )
            }
            <div className="ElementActualite">
                <section class="news-style-four bg-light section-padding">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="block">
                                    <h3 class="news-title">
                                        <span>Actualités</span>
                                    </h3>
                                    <div class="post-overlay-wrapper clearfix" style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${actualites[0].titre}`, actualites[0]._id)}>
                                        <div class="post-thumbnail">
                                            <img class="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${actualites[0].file}`} alt="post-thumbnail" />
                                        </div>

                                        <div class="post-content">
                                            <h2 class="post-title " style={{ color: "white", lineHeight: "20px" }}>
                                                <a >{actualites[0].titre}</a>
                                            </h2>
                                            
                                        </div>
                                    </div>

                                    <div className="post-list-block">

                                        {
                                            actualites.map((data) => {
                                                return (
                                                    <div className="post-block-wrapper post-float clearfix" style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${data.titre}`, data._id)}>
                                                        <div className="post-thumbnail">
                                                            <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${data.file}`} alt="post-thumbnail" />
                                                        </div>

                                                        <div className="post-content">
                                                            <h4 className="post-title title-sm" style={{ fontSize: "12px", lineHeight: "20px" }}>
                                                                <a style={{ fontSize: "12px", lineHeight: "20px" }}>{data.titre}</a>
                                                            </h4>
                                                            
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="block">
                                    <h3 className="news-title">
                                        <span>évènements</span>
                                    </h3>
                                    <div className="post-overlay-wrapper clearfix" style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${evenements[0].titre}`, evenements[0]._id)}>
                                        <div className="post-thumbnail" style={{ maxHeight: "340px" }}>
                                            <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${evenements[0].file}`} alt="post-thumbnail" style={{ maxHeight: "340px" }} />
                                        </div>

                                        <div className="post-content">
                                            <h2 className="post-title" style={{ color: "white", lineHeight: "20px" }}>
                                                <a>{evenements[0].titre}</a>
                                            </h2>
                                            
                                        </div>
                                    </div>

                                    <div className="post-list-block">
                                        {
                                            evenements.map((data) => {
                                                return (
                                                    <div className="post-block-wrapper post-float clearfix" style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${data.titre}`, data._id)}>
                                                        <div className="post-thumbnail">
                                                            <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${data.file}`} alt="post-thumbnail" />
                                                        </div>

                                                        <div className="post-content">
                                                            <h2 className="post-title title-sm" style={{ fontSize: "12px", lineHeight: "20px" }}>
                                                                <a style={{ fontSize: "12px", lineHeight: "20px" }}>{data.titre}</a>
                                                            </h2>
                                                            {/* <div className="post-meta">
                                                    {data.sousTitre.substring(0,30)}
                                                </div> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="block">
                                    <h3 className="news-title">
                                        <span>Fêtes</span>
                                    </h3>
                                    {
                                        fetes.length && (
                                            <div className="post-overlay-wrapper clearfix" style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${fetes[0].titre}`, fetes[0]._id)}>
                                                <div className="post-thumbnail">
                                                    <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${fetes[0].file}`} alt="post-thumbnail" />
                                                </div>

                                                <div className="post-content">
                                                    <h2 className="post-title" style={{ color: "white", lineHeight: "20px" }}>
                                                        <a>{fetes[0].titre}</a>
                                                    </h2>
                                                   
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="post-list-block">
                                        {
                                            fetes.length && (
                                                fetes.map((data) => {
                                                    return (
                                                        <div className="post-block-wrapper post-float clearfix" style={{ cursor: "pointer" }} onClick={() => handlePage(`/actualité/${evenements[0].titre}`, evenements[0]._id)}>
                                                            <div className="post-thumbnail">
                                                                <img className="img-fluid" src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${data.file}`} alt="post-thumbnail" />
                                                            </div>

                                                            <div className="post-content">
                                                                <h2 className="post-title title-sm" style={{ fontSize: "12px", lineHeight: "20px" }}>
                                                                    <a style={{ fontSize: "12px", lineHeight: "20px" }}>{data.titre}</a>
                                                                </h2>
                                                              
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding" style={{ paddingTop: "0px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                <div className="all-news-block">
                                    <h3 className="news-title">
                                        <span>Derniers articles</span>
                                    </h3>
                                    <div className="all-news">
                                        <div className="row">

                                            {
                                                dernierArticle.map((data) => {

                                                    return (
                                                        <div className="col-lg-6 col-md-6" style={{cursor:"pointer"}} onClick={() =>handlePage(`/actualité/${data.titre}`, data._id)}>
                                                            <div className="post-block-wrapper post-float-half clearfix">
                                                                <div className="post-thumbnail">
                                                                    <a>
                                                                        <img className="img-fluid " src={`https://labtech-bakoffice.ufrssmt.edu.ci/images/${data.file}`} alt="post-thumbnail" />
                                                                    </a>
                                                                </div>
                                                                <div className="post-content">
                                                                    
                                                                    <h2 className="post-title mt-3" style={{ lineHeight: "20px" }}>
                                                                        <a>{data.titre}</a>
                                                                    </h2>
                                                                    
                                                                    <p>
                                                                        {`${data.sousTitre.substring(0, 200)}...`}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                               {
                                boutonSuivant === false && (
                                    <nav aria-label="pagination-wrapper" className="pagination-wrapper">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item active" style={{ cursor: "pointer" }} onClick={() => handlePlusData()}>
                                            <a className="page-link " aria-label="Next">
                                                <span className="">Suivant</span>
                                                <span aria-hidden="true"><i className="fa fa-angle-double-right ml-2"></i></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                )
                               }
                            </div>

                            {/* NOS ALUMNIS */}
                            <ListeAlumnis />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default DerniereActualite;