import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const JobPost = () => {

	const [jobsInfo, setJobsInfo] = useState([{ _id: "", domaine: "", type: "", dateLimite: "" }]);
	const navigate = useNavigate()

	const handleChangePage = (path, id) => {
		navigate(path, { state: { id } })
	}

	useEffect(() => {

		const recu_data = async () => {
			let response = await axios.get('https://labtech-bakoffice.ufrssmt.edu.ci/api/accueil/afficher_jobs/')
			setJobsInfo(response.data['info'])
		}

		recu_data()

	}, []);

	return (
		<section class="section-padding" style={{ paddingTop: "0" }}>
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h2 style={{ padding: "0" }}>Postes à pourvoir</h2>
						<hr />

						{
							jobsInfo.map((data, index) => {
								return (
									<div key={index}>
										<div class="d-lg-flex justify-content-between bg-light py-3 px-4 job-item align-items-center" >
											<h6 style={{ width: '70%', fontSize: "15px" }}><a>{data.type}</a></h6>
											<div>{data.dateLimite}</div>
											<a class="btn btn-primary  mt-3 mt-lg-0" style={{ fontSize: "14px", padding: "10px", paddingLeft: "10px" }} href={`/Accueil/job-a-pouvoir/${data.type}/${data._id}`}>Details</a>
										</div>
									</div>
								)
							})
						}

					</div>

					
				</div>
			</div>
		</section>
	)
}

export default JobPost;